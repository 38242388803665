import { JourneyFlow } from '@/v2Lib/journeyFlow';
import { getCurrentStepFlexi } from '@/v2Utils/journey/Flows/Flexi/getCurrentStepFlexi';
import { getCurrentStepBCA } from '@/v2Utils/journey/Flows/BCA/getCurrentStepBCA';

export const getCurrentStepDispatcher = (
  originPage: string,
  flow: string,
): number => {
  switch (flow) {
    case JourneyFlow.FLEXI:
      return getCurrentStepFlexi(originPage);
    case JourneyFlow.BCA:
      return getCurrentStepBCA(originPage);
    default:
      return 0;
  }
};
