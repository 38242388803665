type PencilIconProps = {
  color?: string
}

export const PencilIcon = ({ color = "currentColor" }: PencilIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 17.4991H6.035H6.03583L17.2317 6.30326C17.557 5.97784 17.557 5.45034 17.2317 5.12493L14.8742 2.76743C14.5488 2.44211 14.0213 2.44211 13.6958 2.76743L2.5 13.9633V17.4991ZM14.2848 6.89182L15.4631 5.71349L14.2848 4.53516L13.1064 5.71349L14.2848 6.89182ZM13.107 8.06896L11.9287 6.89062L4.16699 14.6523V15.8306H5.34533L13.107 8.06896Z"
        fill={color}
      />
    </svg>
  );
};
