'use client';

import { useTranslation } from '@/app/i18n/client';
import { PageLayout } from '@/v2Components/PageLayout';
import { useParams } from 'next/navigation';
import { useRef } from 'react';
import { StepTracker } from '@/app/components/StepTracker';
import { applicationRoutePaths } from '@/v2Lib/pageroutes';
import { useJourney } from '@/v2Hooks/useJourney';
import { CompleteStepsFlexi } from '../components/CompleteStepsFlexi';
import { AvailableCapitalCardHero } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/components/AvaiableCapitalCardHero';

export default function CompletedPageFlexi() {
  const { getCurrentStep } = useJourney(applicationRoutePaths.COMPLETE);

  const params: { partner: string } = useParams();
  const { t } = useTranslation();
  const pageLoaded = useRef<boolean>(false);

  return (
    <>
      <StepTracker partner={params.partner} stepName="v2_Complete" />
      <PageLayout partner={params.partner}>
        <PageLayout.Left
          currentStep={getCurrentStep()}
          numberOfSteps={5}
          heading={t('pp-application')}
          partner={params.partner}
          loading={pageLoaded.current}
          footerHeadline={t('pp-yfc-footer-headline')}
        >
          <CompleteStepsFlexi />
        </PageLayout.Left>
        <PageLayout.Right isLoading={false} hiddenOnMobile={false}>
          <AvailableCapitalCardHero />
        </PageLayout.Right>
      </PageLayout>
    </>
  );
}
