'use client';

import { Button } from '@/app/components/Button';
import actions from '@/app/contexts/global/actions';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useTranslation } from '@/app/i18n/client';
import { PageLayout } from '@/v2Components/PageLayout';
import { useAcceptOffer } from '@/v2Hooks/useAcceptOffer';
import { applicationRoutePaths } from '@/v2Lib/pageroutes';
import { useJourney } from '@/v2Hooks/useJourney';
import { useParams, useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { TermsAndExplanationTabs } from '@/v2Components/TermsAndExplanationTabs';
import { Input } from '@/app/components/Input';
import { ShieldIcon } from '@/app/components/icons/Shield';
import { useFeatureFlags } from '@/app/hooks/useFeatureFlags';
import { useUpdateLead } from '@/app/hooks/useUpdateLead';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import {
  scrollToFirstError,
  validateElement,
  validateForm,
} from '@/lib/utils/formValidationHelpers';
import { GenericErrorModal } from '@/v2Components/GenericErrorModal';
import { Label } from '@/v2Components/Label';
import { Loader } from '@/v2Components/Loader';
import { useInstitution } from '@/v2Hooks/useInstitution';
import { EventTypes, UserEvents, useLogEvent } from '@/app/hooks/useLogEvent';
import { StepTracker } from '@/app/components/StepTracker';

export default function BankDetailsPageBca() {
  const { getNextRoute, getCurrentStep } = useJourney(
    applicationRoutePaths.BANKING_INFO,
  );

  const params: { partner: string } = useParams();
  const updateLead = useUpdateLead(params.partner);
  const featureFlags = useFeatureFlags();
  const enableLeadService = featureFlags['enable-lead-service'];
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalStore();
  const router = useRouter();
  const { mutate: sendAcceptOffer, isPending } = useAcceptOffer();
  const pageLoaded = useRef<boolean>(false);
  const [movingNext, setMovingNext] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [domLoaded, setDomLoaded] = useState<boolean>(false);
  const [bankName, setBankName] = useState<string>(
    state?.acceptPayloadBody?.bank_name ?? '',
  );
  const [accountNumber, setAccountNumber] = useState<string>(
    state?.acceptPayloadBody?.account_number ?? '',
  );
  const [routingNumber, setRoutingNumber] = useState<string>(
    state?.acceptPayloadBody?.routing_number ?? '',
  );

  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);
  const [showReturnModal, setShowReturnModal] = useState<boolean>(false);
  const partnerSettings: any = getPartnerSettings(params.partner);

  useEffect(() => {
    if (document.querySelector('#bankName') && domLoaded) {
      validateElement(document.querySelector('#bankName')!);
    }
    setDomLoaded(true);

    if (state?.acceptResponse?.application_id) {
      setShowReturnModal(true);
    }
  }, [bankName]);

  const { mutate: getInstitution } = useInstitution((value: any) => {
    if (value?.name) setBankName(value?.name);
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const valid = validateForm(e.currentTarget, partnerSettings.country);

    if (valid && !isPending) {
      let data: any = {
        bank_details: {
          account_number: accountNumber,
          bank_name: bankName,
          routing_number: routingNumber,
          account_type: 'NONE',
        },
      };

      dispatch(actions.updateAcceptPayloadBody(data));
      setMovingNext(true);
      // Send accept message
      sendAcceptOffer(
        {
          payload: data,
          offerId: state.offerSelected.offer_id,
        },
        {
          onSettled: (data, error) => {
            if (error === null && data?.application_id) {
              router.push(getNextRoute());
            } else {
              setShowError(true);
              setMovingNext(false);
            }
          },
        },
      );
    } else {
      scrollToFirstError();
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
  ) => {
    const targetField = e.target || e.detail.target;
    const inputID = targetField.id;
    const value = targetField.value;

    switch (inputID) {
      case 'bankName': {
        setBankName(value);
        logUserEvent(UserEvents.MERCHANT_SET_BANK_NAME);
        break;
      }
      case 'accountNumber': {
        setAccountNumber(value);
        break;
      }
      case 'routingNumber': {
        if (targetField.validity.valid) {
          getInstitution(value);
        }
        setRoutingNumber(value);
        break;
      }
    }
  };

  const returnToQuote = () => {
    router.push(applicationRoutePaths.YOUR_QUOTE_URL);
  };

  useEffect(() => {
    if (enableLeadService) {
      updateLead();
    }
  }, [enableLeadService]);

  return (
    <>
      <StepTracker partner={params.partner} stepName="v2_BankDetails" />
      <PageLayout partner={params.partner}>
        <PageLayout.Left
          currentStep={getCurrentStep()}
          numberOfSteps={5}
          heading={t('pp-bkd-page-heading')}
          partner={params.partner}
          loading={pageLoaded.current}
        >
          {showError && (
            <GenericErrorModal
              open={showError}
              header={t('pp-accept-offer-fail-generic-header')}
              text={t('pp-accept-offer-fail-generic-text').replace(
                '{contactPhoneNumber}',
                partnerSettings?.contactPhoneNumber ?? '',
              )}
              showButton={true}
              buttonText={t('pp-offer-fail-return-text')}
              buttonProps={{
                implementAsAnchor: true,
                mixpanelButtonText: 'unknown seller id return button',
                id: 'returnToPartner',
                href: partnerSettings.profileReturnLink,
              }}
            />
          )}

          <GenericErrorModal
            open={showReturnModal}
            header={t('pp-contract-return-after-accepting-header')}
            text={t('pp-contract-return-after-accepting-text').replace(
              '{contactPhoneNumber}',
              partnerSettings?.contactPhoneNumber ?? '',
            )}
            showButton={true}
            buttonText={t(
              'pp-contract-return-after-accepting-text-button-text',
            )}
            buttonProps={{
              implementAsAnchor: true,
              mixpanelButtonText:
                'seller returned to contracts after completing now going forward',
              id: 'returnLastPage',
              href: getNextRoute(),
            }}
          />

          <form
            className="flex-1 flex flex-col"
            onSubmit={handleSubmit}
            noValidate
          >
            <h3 className="text-core-8 text-lg pt-0 pb-0">
              {t('pp-bkd-heading')}
            </h3>
            <p className="text-core-7 text-sm pt-4 pb-10">
              {t('pp-bkd-intro-copy')}
            </p>
            <div className="flex-1">
              <div className="grid grid-col-1 tablet:grid-col-2 gap-y-4 gap-x-8">
                <span>
                  <Label htmlFor="accountNumber">
                    {t('pp-bank-account-number')}
                  </Label>
                  <Input
                    type="text"
                    id="accountNumber"
                    required
                    value={accountNumber}
                    onChange={handleChange}
                    errorMessage={t('pp-bank-account-number-error-message')}
                    placeholder={t('pp-bank-account-number-placeholder')}
                  />
                </span>
                <span>
                  <Label htmlFor="routingNumber">
                    {t('pp-bank-routing-number')}
                  </Label>
                  <Input
                    type="text"
                    id="routingNumber"
                    required
                    value={routingNumber}
                    onChange={handleChange}
                    errorMessage={t('pp-bank-routing-number-error-message')}
                    placeholder={t('pp-bank-routing-number-placeholder')}
                    pattern="\d{9}"
                  />
                </span>
                <span className="tablet:col-span-2">
                  <Label htmlFor="bankName">{t('pp-bank-name')}</Label>
                  <Input
                    type="text"
                    id="bankName"
                    required
                    value={bankName}
                    onChange={handleChange}
                    errorMessage={t('pp-bank-name-error-message')}
                  />
                </span>
              </div>
            </div>
            <div className="bg-primary/5 px-6 py-4 rounded-xl mb-6">
              <h3 className="text-sm text-primary mb-4 font-bold pl-7 relative">
                <span className="inline-block absolute -left-1 w-5 h-5 leading-6 ">
                  <ShieldIcon />
                </span>
                {t('pp-bkd-please-note-header')}
              </h3>
              <p className="text-sm text-core-7">{t('pp-bkd-no-edit-copy')}</p>
            </div>
            <Button
              type="submit"
              mixpanelButtonText={'business detail 2 continue'}
              id="businessDetailStep2"
              isLoading={movingNext}
            >
              {!movingNext ? (
                t('pp-continue')
              ) : (
                <>
                  <div className="h-7 text-center w-full" aria-hidden>
                    <Loader />
                  </div>
                  <span className="sr-only">
                    {t('pp-loading-sr-only-message')}
                  </span>
                </>
              )}
            </Button>
            <button
              type="button"
              className="text-core-7 cursor-pointer font-heavy hover:underline mt-8 laptop:hidden"
              id="cancel"
              onClick={returnToQuote}
            >
              {t('pp-return-to-quote')}
            </button>
          </form>
        </PageLayout.Left>

        <PageLayout.Right isLoading={false} hiddenOnMobile={true}>
          <TermsAndExplanationTabs allowEdit={true} />
        </PageLayout.Right>
      </PageLayout>
    </>
  );
}
