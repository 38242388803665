const BusinessDetailsIcon = (): React.ReactNode => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            id="Shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 3H3V5H21V3ZM21 13V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V13H2V11L3 6H21L22 11V13H21ZM5 13V19H19V13H5ZM19.96 11H4.04L4.64 8H19.36L19.96 11ZM6 14H14V17H6V14Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export { BusinessDetailsIcon };
