import { useTranslation } from '@/app/i18n/client';
import { Loader } from '@/v2Components/Loader';

export const YourFlexiQuoteLoading = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-2" data-testid="your-flexi-quote-loading">
      <h3 className="text-core-8 text-lg pt-0 pb-0">
        {t('pp-yfq-heading-loading')}
      </h3>

      <h4 className="text-core-7 pt-0 pb-0">
        {t('pp-yfq-sub-heading-loading')}
      </h4>

      <div className="w-16 h-16 mx-auto">
        <Loader />
      </div>
    </div>
  );
};
