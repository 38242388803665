'use client';

import { Button } from '@/app/components/Button';
import { ParsedText } from '@/app/components/ParsedText';
import actions, { ReviewFormDetails } from '@/app/contexts/global/actions';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useFeatureFlags } from '@/app/hooks/useFeatureFlags';
import { EventTypes, useLogEvent, UserEvents } from '@/app/hooks/useLogEvent';
import { useUpdateLead } from '@/app/hooks/useUpdateLead';
import { useTranslation } from '@/app/i18n/client';
import {
  businessTypeValues,
  getBusinessTypeFamily,
} from '@/lib/businessTypeRoutes';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { Applicant } from '@/lib/types/OffersPayload.types';
import { ValidateCompletedFlexiOffersPayload } from '@/lib/types/ValidateCompletedOffersPayload.types';
import { isFlexiUserInputDataComplete } from '@/lib/utils/isUserInputDataComplete';
import { useKYCCheck } from '@/v2Hooks/useKYCCheck';
import { applicationRoutePaths } from '@/v2Lib/pageroutes';
import { useJourney } from '@/v2Hooks/useJourney';
import { useParams, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { UserIcon } from '../../../../(Journey)/components/UserIcon';
import { Popup } from '../../../../components/Popup';
import { Loader } from '@/v2Components/Loader';
import { PageLayout } from '@/v2Components/PageLayout';
import { StepTracker } from '@/app/components/StepTracker';
import { BankingDetailsReviewSection } from '../components/BankingDetails/BankingDetailsReviewSection';
import { AvailableCapitalCardHero } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/components/AvaiableCapitalCardHero';
import { useFlexiCreateApplication } from '@/v2Hooks/flexi/useFlexiCreateApplication';
import { KycErrorModal } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/KycErrorModal';
import { CompanyDetailsReviewSection } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/CompanyDetails/CompanyDetailsReviewSection';
import { ApplicantDetailsReviewSection } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/PersonalDetails/ApplicantDetailsReviewSection';
import { FirstShareholderDetailsReviewSection } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/PersonalDetails/FirstShareholderDetailsReviewSection';
import { RemainingShareholderDetailsReviewSection } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/PersonalDetails/RemainingShareholderDetailsReviewSection';
import { ApplicationConflictErrorModal } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/ApplicationConflictErrorModal';
import { ApplicationCriticalErrorModal } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/ApplicationCriticalErrorModal';

const customPopupStyles = {
  content: {
    zIndex: 10,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '30rem',
    width: '100%',
    background: 'white',
    borderRadius: '12px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(14, 11, 35, 0.75)',
  },
};

export default function ReviewPageFlexi() {
  const { getNextRoute, getCurrentStep } = useJourney(
    applicationRoutePaths.REVIEW_URL,
  );

  const { t } = useTranslation();
  const [shareholderModalOpen, setShareholderModalOpen] =
    useState<boolean>(false);
  const params: { partner: string; lang: string } = useParams();
  const updateLead = useUpdateLead(params.partner);
  const featureFlags = useFeatureFlags();
  const enableLeadService = featureFlags['enable-lead-service'];
  const [userInputValid, setUserInputValid] =
    useState<ValidateCompletedFlexiOffersPayload>({
      application: false,
      company: false,
      applicants: [],
      bankDetails: false,
    });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageState, setPageState] = useState<any>(true);
  const { state, dispatch } = useGlobalStore();
  const router = useRouter();
  const {
    mutate: KYCCheck,
    isPending: kycPending,
    failureCount,
  } = useKYCCheck();
  const partnerSettings: any = getPartnerSettings(params.partner);
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);
  const [movingNext, setMovingNext] = useState<boolean>(false);
  const [showKYCFailure, setShowKYCFailure] = useState<boolean>(false);
  const [showApplicationFailure, setShowApplicationFailure] =
    useState<boolean>(false);
  const [showApplicationCriticalError, setShowApplicationCriticalError] =
    useState<boolean>(false);
  const [showApplicationConflictError, setShowApplicationConflictError] =
    useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const { createApplication, isPending: createApplicationIsPending } =
    useFlexiCreateApplication({
      application: state.offersPayload.application,
      applicants: state.offersPayload.applicants,
      company: state.offersPayload.company,
      bankDetails: state.acceptPayloadBody?.bank_details,
      consents: state.offersPayload.consents,
      offer: state.offersResponse,
    });

  const showLTDShareholderModal = () => {
    // find percentage
    let totalShareholderPercentage = 0;
    state.offersPayload.applicants.forEach((shareholder: any) => {
      totalShareholderPercentage += shareholder.ownership_percentage;
    });

    // show warning modal
    if (totalShareholderPercentage < 50.1) {
      logUserEvent(UserEvents.SHAREHOLDER_WARNING_SHOWN);
      setShareholderModalOpen(true);
      return;
    }
    moveToNextPageKYC();
  };

  const closeKYCModal = () => {
    setShowKYCFailure(false);
    setMovingNext(false);
  };

  const closeApplicationModal = () => {
    setShowApplicationFailure(false);
    setMovingNext(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isLTD =
      state?.offersPayload?.company?.business_type &&
      getBusinessTypeFamily(state?.offersPayload?.company?.business_type) ===
        businessTypeValues.limited_company;

    // Language override and consent needs setting
    const overrideLocale = (params?.lang ?? '').replace('-', '_');
    const extraRequestFields = {
      bureauSearch: true,
    } as ReviewFormDetails;

    if (overrideLocale && overrideLocale !== partnerSettings.locale) {
      extraRequestFields.overrideLocaleKey = overrideLocale;
    }
    dispatch(actions.updateOffersPayload(extraRequestFields));

    // update stage
    dispatch(actions.updateStage({ 'ownership-details': 'complete' }));

    // log number of shareholders for ltd company
    if (isLTD) {
      showLTDShareholderModal();
    } else {
      moveToNextPageKYC();
    }
  };

  const closeShareholderModal = () => {
    setShareholderModalOpen(false);
  };

  const continueAnyway = async () => {
    logUserEvent(UserEvents.CONTINUED_AFTER_SHAREHOLDER_WARNING);
    closeShareholderModal();
    moveToNextPageKYC();
  };

  const moveToNextPageKYC = async () => {
    setMovingNext(true);

    if (kycPending) return;

    KYCCheck(
      {
        payload: state.offersPayload,
      },
      {
        onSettled: (data, error) => {
          if (error === null && data?.kycStatus) {
            moveToNextPageCreateApplication();
          } else if (data.kycStatus === false) {
            setShowKYCFailure(true);
          } else {
            setMovingNext(false);
            setShowError(true);
          }
        },
      },
    );
  };

  const moveToNextPageCreateApplication = async () => {
    if (createApplicationIsPending) return;

    createApplication(undefined, {
      onSettled: (data, error) => {
        if (data === 200) {
          router.push(getNextRoute());
        } else if (data && data == 400) {
          setMovingNext(false);
          setShowApplicationFailure(true);
        } else if (data && data == 409) {
          setMovingNext(false);
          setShowApplicationConflictError(true);
        } else if (data && (data >= 400 || data < 599)) {
          setMovingNext(false);
          setShowApplicationCriticalError(true);
        } else {
          setMovingNext(false);
          setShowError(true);
        }
      },
    });
  };

  const cleanUpOnClose = () => {
    document.body.classList.remove('ReactModal__Body--open');
    document.getElementById('appRoot')?.removeAttribute('aria-hidden');
  };

  const isLTDWithShareholder =
    getBusinessTypeFamily(state?.offersPayload?.company?.business_type) ===
      businessTypeValues.limited_company &&
    state?.offersPayload?.applicants?.length > 1;

  const addShareholder = () => {
    logUserEvent(UserEvents.ADD_SHAREHOLDER_FROM_SHAREHOLDER_WARNING);
    router.push(getNextRoute({ addShareholder: true }));
  };

  /******
   * Remove any unrequired applicant details i.e. Merchant enters a partnership or ltd
   * and then eidts to become a sole trader
   *  */
  useEffect(() => {
    if (
      state?.offersPayload?.company?.business_type &&
      getBusinessTypeFamily(state?.offersPayload?.company?.business_type) ===
        businessTypeValues.sole_trader &&
      state?.offersPayload?.applicants?.length > 1
    ) {
      dispatch(actions.removeShareholders());
    }

    if (
      state?.offersPayload?.company?.business_type &&
      getBusinessTypeFamily(state?.offersPayload?.company?.business_type) ===
        businessTypeValues.partnership &&
      state?.offersPayload?.applicants?.length > 2
    ) {
      let applicants = state?.offersPayload?.applicants;

      if (Array.isArray(applicants) && applicants?.length > 2) {
        applicants = applicants.slice(0, 2);
      }
      const newState = { ...state };
      newState.offersPayload.applicants = applicants;
      dispatch(actions.updateOffersPayload(newState));
    }

    if (
      state?.offersPayload?.company?.business_type &&
      getBusinessTypeFamily(state?.offersPayload?.company?.business_type) ===
        businessTypeValues.partnership
    ) {
      let applicants = state?.offersPayload?.applicants;
      applicants = applicants.map((applicant: Applicant) => {
        applicant.ownership_percentage = 50;
        return applicant;
      });

      const newState = { ...state };
      newState.offersPayload.applicants = applicants;
      dispatch(actions.updateOffersPayload(newState));
    }

    if (
      state?.offersPayload?.company?.business_type &&
      getBusinessTypeFamily(state?.offersPayload?.company?.business_type) ===
        businessTypeValues.sole_trader
    ) {
      let applicants = state?.offersPayload?.applicants;
      applicants = applicants.map((applicant: Applicant) => {
        applicant.ownership_percentage = 100;
        return applicant;
      });

      const newState = { ...state };
      newState.offersPayload.applicants = applicants;
      dispatch(actions.updateOffersPayload(newState));
    }

    if (state?.offersPayload?.company) {
      setUserInputValid(
        isFlexiUserInputDataComplete(
          partnerSettings.country,
          state.offersPayload?.company.business_type,
          state.offersPayload,
          state.indicativeQuoting,
          state.acceptPayloadBody?.bank_details,
        ),
      );
    }
    setPageState(state);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (enableLeadService) {
      updateLead();
    }
  }, [enableLeadService]);

  useEffect(() => {
    if (failureCount >= 3) {
      setShowError(true);
    }
  }, [failureCount]);

  const showFirstShareholder =
    pageState?.offersPayload?.company?.business_type ===
      businessTypeValues.partnership || isLTDWithShareholder;
  const showRemainingShareholders =
    getBusinessTypeFamily(pageState?.offersPayload?.company?.business_type) ===
      businessTypeValues.limited_company &&
    Array.isArray(pageState?.offersPayload?.applicants) &&
    pageState?.offersPayload?.applicants?.length > 2;
  const showAddShareholder =
    getBusinessTypeFamily(pageState?.offersPayload?.company?.business_type) ===
    businessTypeValues.limited_company;

  return (
    <>
      <StepTracker partner={params.partner} stepName="v2_Review" />
      <PageLayout partner={params.partner}>
        <PageLayout.Left
          currentStep={getCurrentStep()}
          numberOfSteps={5}
          heading={t('pp-application')}
          partner={params.partner}
          loading={isLoading}
          footerHeadline={t('pp-footer-headline')}
        >
          <KycErrorModal
            show={showError}
            contactPhoneNumber={partnerSettings?.contactPhoneNumber}
            profileReturnLink={partnerSettings?.profileReturnLink}
          />
          <ApplicationConflictErrorModal
            show={showApplicationConflictError}
            contactEmail={partnerSettings?.contactEmail}
            profileReturnLink={partnerSettings?.profileReturnLink}
          />
          <ApplicationCriticalErrorModal
            show={showApplicationCriticalError}
            contactPhoneNumber={partnerSettings?.contactPhoneNumber}
            profileReturnLink={partnerSettings?.profileReturnLink}
          />
          <form
            className="flex-1 flex flex-col"
            onSubmit={handleSubmit}
            noValidate
          >
            <h2 className="text-core-8  text-lg mb-3 leading-5 group-[.loading]/main:animate-pulse group-[.loading]/main:bg-core-3 group-[.loading]/main:!text-core-3">
              {getBusinessTypeFamily(
                pageState?.offersPayload?.company?.business_type,
              ) === businessTypeValues.limited_company
                ? t('pp-r-heading-ltd')
                : t('pp-r-heading')}
            </h2>
            <p className="text-sm mb-8 text-core-7 group-[.loading]/main:animate-pulse group-[.loading]/main:bg-core-3 group-[.loading]/main:!text-core-3">
              {getBusinessTypeFamily(
                pageState?.offersPayload?.company?.business_type,
              ) === businessTypeValues.limited_company
                ? t('pp-r-intro-copy-ltd')
                : t('pp-r-intro-copy')}
            </p>

            <div className="flex-1 pb-12">
              {/* Business details */}
              <CompanyDetailsReviewSection
                isComplete={userInputValid.company}
                editUrl={getNextRoute({
                  editBusinessDetails: true,
                })}
                pageState={pageState}
              />
              {/* Applicant details */}
              <ApplicantDetailsReviewSection
                isComplete={userInputValid.applicants[0]}
                editUrl={`${getNextRoute({
                  editPersonalDetails: true,
                })}/0`}
                pageState={pageState}
              />
              {/* First shareholder or partner details */}
              {showFirstShareholder && (
                <FirstShareholderDetailsReviewSection
                  isComplete={userInputValid?.applicants?.[1]}
                  editUrl={`${getNextRoute({
                    editPersonalDetails: true,
                  })}/1`}
                  pageState={pageState}
                />
              )}
              {/* Limited company shareholders over not including the applicant and first shareholder */}
              {showRemainingShareholders &&
                pageState?.offersPayload?.applicants.map(
                  (shareholder: any, index: number) => {
                    if (index <= 1) return null;
                    return (
                      <RemainingShareholderDetailsReviewSection
                        key={`applicant${shareholder?.first_name}_${shareholder?.last_name}_${index}`}
                        isComplete={userInputValid.applicants[index]}
                        editUrl={`${getNextRoute({
                          editPersonalDetails: true,
                        })}/${index}`}
                        pageState={pageState}
                        index={index}
                      />
                    );
                  },
                )}
              <BankingDetailsReviewSection
                isComplete={userInputValid.bankDetails}
                editUrl={getNextRoute({
                  editBankDetails: true,
                })}
                bankDetails={pageState?.acceptPayloadBody?.bank_details}
              />
              {showAddShareholder && (
                <div className="mt-10">
                  <Button
                    type="button"
                    onclick={addShareholder}
                    id="addShareholder"
                    mixpanelButtonText="add-shareholder"
                    successVariant={true}
                  >
                    <span className="w-8 h-4 inline-block">
                      <UserIcon />
                    </span>
                    <span>{t('add-shareholder-button-label')}</span>
                  </Button>
                </div>
              )}
            </div>

            <p className="text-xs text-core-7 mb-10">
              <ParsedText htmlString={t('pp-review-consent')} />
            </p>
            <Button
              type="submit"
              mixpanelButtonText={'review details get quote'}
              id="reviewDetailsContinue"
              isLoading={movingNext}
            >
              {!movingNext ? (
                t('pp-continue')
              ) : (
                <>
                  <div className="h-7 text-center w-full" aria-hidden>
                    <Loader />
                  </div>
                  <span className="sr-only">
                    {t('pp-loading-sr-only-message')}
                  </span>
                </>
              )}
            </Button>
          </form>
        </PageLayout.Left>

        <PageLayout.Right hiddenOnMobile={true}>
          <AvailableCapitalCardHero />
        </PageLayout.Right>
      </PageLayout>
      <Popup
        isOpen={shareholderModalOpen}
        onRequestClose={continueAnyway}
        onBackgroundClick={() => setShareholderModalOpen(false)}
        style={customPopupStyles}
        onAfterClose={cleanUpOnClose}
        heading={
          <h2 className="text-header-m mb-5 pt-5 text-center">
            {t('shareholder-warning-modal-heading')}
          </h2>
        }
        body={
          <div className="block font-light text-base mb-5 pl-5 pr-5 text-center">
            <ParsedText htmlString={t('shareholder-warning-modal-content')} />
          </div>
        }
        element={
          <div className="mb-5 w-[20rem]">
            <Button
              mixpanelButtonText="shareholderPercentageWarning"
              id="shareholderPercentageWarning"
              onclick={addShareholder}
            >
              {t('shareholder-warning-modal-button')}
            </Button>
          </div>
        }
        closeText={t('shareholder-warning-modal-close')}
      />
      <Popup
        isOpen={showKYCFailure}
        onRequestClose={closeKYCModal}
        onBackgroundClick={closeKYCModal}
        style={customPopupStyles}
        onAfterClose={cleanUpOnClose}
        heading={
          <h2 className="text-lg mb-6 pt-5 text-center">
            {t('pp-review-kyc-flexi-fail-modal-heading')}
          </h2>
        }
        body={
          <div className="block text-sm mb-6  text-center">
            <ParsedText
              htmlString={t('pp-review-kyc-fail-modal-content-flexi')}
            />
          </div>
        }
        element={
          <div className="mb-5 max-w-[20rem] w-full flex flex-col gap-4">
            <Button
              mixpanelButtonText="shareholderPercentageWarning"
              id="shareholderPercentageWarning"
              onclick={closeKYCModal}
            >
              {t('pp-review-kyc-fail-modal-button')}
            </Button>
            <Button
              href={partnerSettings.profileReturnLink}
              id={'kycFailureReturnButton'}
              mixpanelButtonText={'Kyc failure return to ebay'}
              implementAsAnchor={true}
              className={'underline text-center'}
            >
              {t('pp-generic-return-text')}
            </Button>
          </div>
        }
      />

      <Popup
        isOpen={showApplicationFailure}
        onRequestClose={closeApplicationModal}
        onBackgroundClick={closeApplicationModal}
        style={customPopupStyles}
        onAfterClose={cleanUpOnClose}
        heading={
          <h2 className="text-lg mb-10 pt-5 text-center">
            {t('pp-review-kyc-flexi-fail-modal-heading')}
          </h2>
        }
        body={
          <div className="block text-sm mb-10  text-center">
            <ParsedText
              htmlString={t('pp-review-kyc-fail-modal-content-flexi')}
            />
          </div>
        }
        element={
          <div className="mb-5 max-w-[20rem] w-full">
            <Button
              mixpanelButtonText="shareholderPercentageWarning"
              id="shareholderPercentageWarning"
              onclick={closeApplicationModal}
            >
              {t('pp-review-kyc-fail-modal-button')}
            </Button>
          </div>
        }
      />
    </>
  );
}
