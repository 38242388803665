import { applicationRoutePaths } from '@/v2Lib/pageroutes';

export const getCurrentStepFlexi = (originPage: string): number => {
  switch (originPage) {
    case applicationRoutePaths.BUSINESS_DETAILS_PART_ONE_URL:
    case applicationRoutePaths.BUSINESS_DETAILS_PART_TWO_URL:
      return 1;

    case applicationRoutePaths.YOUR_DETAILS_PART_ONE_URL:
    case applicationRoutePaths.YOUR_DETAILS_PART_TWO_URL:
    case applicationRoutePaths.SHAREHOLDER_DETAILS_URL:
      return 2;

    case applicationRoutePaths.YOUR_QUOTE_URL:
      return 3;

    case applicationRoutePaths.BANKING_INFO:
      return 4;

    case applicationRoutePaths.REVIEW_URL:
    case applicationRoutePaths.EDIT_BUSINESS_DETAILS_URL:
    case applicationRoutePaths.EDIT_PERSONAL_DETAILS_URL:
    case applicationRoutePaths.EDIT_BANKING_DETAILS_URL:
    case applicationRoutePaths.COMPLETE:
      return 5;

    default:
      return 0;
  }
};
