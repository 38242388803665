import { useTranslation } from '@/app/i18n/client';
import { LoadingButton } from './LoadingButton';

const ContinueButton = ({
  type,
  onClick,
  mixPanelText,
  isLoading,
  id,
}: {
  mixPanelText: string;
  isLoading: boolean;
  type: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
  id: string;
}) => {
  const { t } = useTranslation();

  return (
    <LoadingButton
      type={type}
      onClick={onClick}
      mixPanelText={mixPanelText}
      id="id"
      isLoading={isLoading}
      label={t('pp-continue')}
    />
  );
};

export { ContinueButton };
