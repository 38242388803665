import * as React from 'react';
import { SVGProps } from 'react';

export const FeeTabPlaygroundIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <mask
        id="b"
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill="#D9D9D9" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#1C1B1F"
          d="M11.825 22c-.4 0-.783-.075-1.15-.225a2.942 2.942 0 0 1-.975-.65L4.6 16l.75-.775c.267-.267.58-.446.938-.537a1.998 1.998 0 0 1 1.062.012l1.65.475V7c0-.283.096-.52.287-.713A.968.968 0 0 1 10 6c.283 0 .52.096.713.287.191.192.287.43.287.713v10.825l-2.425-.675 2.55 2.55c.083.083.188.154.313.213a.907.907 0 0 0 .387.087H16c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413v-4c0-.283.096-.52.288-.713A.968.968 0 0 1 19 13c.283 0 .52.096.712.287.192.192.288.43.288.713v4c0 1.1-.392 2.042-1.175 2.825C18.042 21.608 17.1 22 16 22h-4.175ZM12 15v-4c0-.283.096-.52.287-.713A.968.968 0 0 1 13 10c.283 0 .52.096.713.287.191.192.287.43.287.713v4h-2Zm3 0v-3c0-.283.096-.52.287-.713A.968.968 0 0 1 16 11c.283 0 .52.096.712.287.192.192.288.43.288.713v3h-2ZM2 7c.5-1.767 1.683-3.208 3.55-4.325C7.417 1.558 9.567 1 12 1c1.567 0 3.075.258 4.525.775a13.239 13.239 0 0 1 3.975 2.25V2H22v5h-5V5.5h2.9a12.056 12.056 0 0 0-3.675-2.225A11.72 11.72 0 0 0 12 2.5c-1.967 0-3.704.4-5.213 1.2C5.28 4.5 4.208 5.6 3.575 7H2Z"
        />
      </g>
    </g>
  </svg>
);
export default FeeTabPlaygroundIcon;
