import actions from '@/app/contexts/global/actions';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { GenericErrorModal } from '@/v2Components/GenericErrorModal';
import { useTranslation } from 'react-i18next';
import { useJourney } from '../../../hooks/useJourney';
import { applicationRoutePaths } from '@/v2Lib/pageroutes';
import { useRouter } from 'next/navigation';

export type QuoteRedirectToBcaModalProps = {
  show: boolean;
};

export const QuoteRedirectToBcaModal = (
  props: QuoteRedirectToBcaModalProps,
) => {
  const router = useRouter();
  const { getNextRoute } = useJourney(applicationRoutePaths.YOUR_QUOTE_URL);

  const { dispatch } = useGlobalStore();
  const { t } = useTranslation();

  const handleRedirectToBca = () => {
    dispatch(actions.setRedirectToBca());
    router.push(getNextRoute({ redirectToBca: true }));
  };

  return (
    <GenericErrorModal
      open={props.show}
      header={t('pp-yfq-redirect-to-bca-header')}
      text={t('pp-yfq-redirect-to-bca-text')}
      showButton={true}
      buttonText={t('pp-yfq-redirect-to-bca-button')}
      buttonProps={{
        implementAsAnchor: true,
        mixpanelButtonText: 'Quote endpoint redirect to BCA',
        id: 'changeToBcaJourney',
        onclick: handleRedirectToBca,
      }}
    />
  );
};
