import AvailableCapitalGauge from './AvailableCapitalGauge';
import { useTranslation } from '@/app/i18n/client';

type AvailableCapitalProps = {
  currency: string;
  availableCapital: number;
  months: number;
  split: number;
};

export const AvailableCapitalCard = ({
  currency,
  availableCapital,
  months,
  split,
}: Readonly<AvailableCapitalProps>) => {
  const { t } = useTranslation();

  const labelValue = (label: string, value: string) => (
    <div className="flex justify-between text-sm leading-[22px]">
      <span className="font-normal text-gray-500">{label}</span>
      <span className="font-bold text-gray-800">{value}</span>
    </div>
  );

  return (
    <div
      className="border border-gray-300 rounded-lg"
      data-testid="avaialable-capital-card"
    >
      <div className="border-b border-gray-300 p-4">
        <h3 className="text-lg text-gray-800">
          {t('pp-yfq-available-capital')}
        </h3>
      </div>
      <div className="p-4">
        <div className="flex flex-col gap-2">
          <div className="flex justify-center w-full">
            <AvailableCapitalGauge
              currency={currency}
              availableCapital={availableCapital}
            />
          </div>
          {labelValue(
            t('pp-yfq-available-capital-for'),
            `${t('pp-yfq-available-capital-period', { months })}*`,
          )}
          <hr className="my-2 border-gray-300" />
          {labelValue(
            t('pp-yfq-fee'),
            `${t('pp-yfq-fee-based-on-advance-amount')}*`,
          )}
          <hr className="my-2 border-gray-300" />
          {labelValue(
            t('pp-yfq-your-payments'),
            t('pp-yfq-your-payments-split', { split }),
          )}
        </div>
      </div>
    </div>
  );
};
