import { Amount, AmountProps } from '@/app/components/Amount';
import { ParsedText } from '@/app/components/ParsedText';
import { Slider } from '@/app/components/Slider';
import { PencilIcon } from '@/app/components/icons/pencil';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useEffect, useRef, useState } from 'react';
import actions from '@/app/contexts/global/actions';
import { useDebouncedCallback } from 'use-debounce';

const QuoteSelectButton = ({
  selected,
  title,
  split,
  fee,
  isPending,
  onClick,
  totalNumberOfOffers,
  index,
}: {
  selected: boolean;
  title: string;
  split: number;
  fee: number;
  isPending: boolean;
  onClick: () => void;
  totalNumberOfOffers: number;
  index: number;
}) => {
  const { t } = useTranslation();
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const intlConfig: AmountProps['intlConfig'] = {
    locale: partnerSettings.locale.replace('_', '-'),
    currency: partnerSettings.currency,
  };

  const pendingButtonCSS = isPending ? 'pointer-none !border-core-4' : '';
  const pendingCSS = isPending ? 'bg-core-3 !text-core-3 animate-pulse' : '';

  const offerLabel = (() => {
    if (totalNumberOfOffers === 3) {
      if (index === 0) {
        return t('pp-yd-quick-label');
      } else if (index === 1) {
        return t('pp-yd-balanced-label');
      } else if (index === 2) {
        return t('pp-yd-extended-label');
      }
    }

    if (totalNumberOfOffers === 2) {
      if (index === 0) {
        return t('pp-yd-quick-label');
      } else if (index === 1) {
        return t('pp-yd-extended-label');
      }
    }

    if (totalNumberOfOffers === 1) {
      return t('pp-yd-single-offer-label');
    }
  })();

  return (
    <button
      className={`border border-core-5 w-full rounded-md pt-4 tablet:pt-2 pb-8 tablet:pb-6 px-2 tablet:aspect-square  ${
        selected && !isPending
          ? 'border-primary bg-primary/5'
          : 'hover:bg-primary/5 hover:border-primary/50'
      } ${pendingButtonCSS}`}
      type="button"
      onClick={onClick}
    >
      <div className={`text-core-7 font-medium mb-2  ${pendingCSS}`}>
        {offerLabel}
      </div>
      <div className={`text-sm text-core-7 mb-1 ${pendingCSS}`}>{`${split}${t(
        'pp-yd-button-split-label',
      )}`}</div>
      <div className={`mr-1 text-sm text-core-7 ${pendingCSS}`}>
        $
        <Amount>{fee}</Amount>
        {t('pp-yd-button-fee-label')}
      </div>
    </button>
  );
};

const CurrencyInput = ({
  currencySymbol,
  value,
  onChange,
  error,
  max,
  disabled = false,
  min,
}: {
  currencySymbol: string;
  value: string;
  onChange: (e: string) => void;
  max: number;
  min: number;
  error: boolean;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  let inputValue = '';

  if (value) {
    let unformatedValue = Number(value.replace(/\D/g, ''));

    inputValue = new Intl.NumberFormat(
      partnerSettings.locale.replace('_', '-'),
    ).format(Number(unformatedValue));
  }

  if (inputValue === '0') inputValue = '';

  return (
    <div
      className={`relative rounded border pl-5 focus-within:outline focus-within:outline-2 focus-within:border-primary/5 min-w-[100px] smobile:min-w-[140px] ${
        error
          ? 'border-error-1 bg-error-2/50 focus-within:outline-error-1'
          : 'border-primary pl-5 bg-primary/5'
      } ${
        !max ? 'bg-core-3/100 animate-pulse text-core-3 !border-core-3 h-9' : ''
      } ${disabled ? '!bg-core-3 !border-core-4' : ''}`}
      aria-hidden={!max}
    >
      {max && (
        <>
          <span className="z-1 absolute text-sm top-[8px] -ml-3 text-core-7 pointer-events-none">
            {currencySymbol}
          </span>
          <span className="z-1 absolute text-sm h-5 w-5 text-core-7 top-[7px] right-1 pointer-events-none">
            <PencilIcon />
          </span>
          <label className="sr-only" htmlFor="requestedAmountInput">
            {t('pp-yd-input-label-sr-only')
              .replace('{min}', `${min}`)
              .replace('{max}', `${max}`)}
          </label>
          <input
            className="z-2 aboslute left-0 right-0 border-0 rounded bg-transparent focus:outline-none text-sm -ml-5 pl-5 pr-2 py-2 "
            value={inputValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              onChange(value);
            }}
            id="requestedAmountInput"
            disabled={disabled}
          />
          <div
            aria-hidden={!error}
            className="absolute top-10 left-0 min-w-[250px] text-error-1 text-sm"
          >
            {error
              ? t('pp-yd-amount-input-error')
                  .replace('{min}', `${min}`)
                  .replace('{max}', `${max}`)
              : null}
          </div>
        </>
      )}
    </div>
  );
};

const QuoteEditor = ({
  isPending,
  onSelectOffer,
  handleLoadingState,
}: {
  isPending: boolean;
  onSelectOffer: (id: string) => void;
  handleLoadingState: (value: boolean) => void;
}) => {
  const { state, dispatch } = useGlobalStore();
  const { t } = useTranslation();
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const intlConfig: AmountProps['intlConfig'] = {
    locale: partnerSettings.locale.replace('_', '-'),
    currency: partnerSettings.currency,
  };
  const [disableInputs, setDisableInputs] = useState<boolean>(false);
  const [amountRequested, setAmountRequested] = useState<number>(
    state?.offersResponse?.products?.[0]?.limits?.maximum || 0,
  );
  const [showInputError, setShowInputError] = useState<boolean>(false);
  const amountHasBeenManuallyChanged = useRef<boolean>(false);

  const handleAmountRequestedChange = (value: string) => {
    amountHasBeenManuallyChanged.current = true;
    let unformatedValue = Number(value.replace(/\D/g, ''));
    if (
      unformatedValue > state?.offersResponse?.products[0]?.limits?.maximum ||
      unformatedValue < state?.offersResponse?.products[0]?.limits?.minimum
    ) {
      setShowInputError(true);
    } else {
      setShowInputError(false);
    }
    setAmountRequested(Number(value.replace(/\./g, '').replace(/\,/g, '')));
  };

  const getSliderStepIncrement = () => {
    const max = state.offersResponse?.products?.[0].limits?.maximum;
    const min = state.offersResponse?.products?.[0].limits?.minimum;

    if (max % 100 === 0 && min % 100 === 0) {
      return 100;
    }
    if (max % 10 === 0 && min % 10 === 0) {
      return 10;
    }
    return 1;
  };

  useEffect(() => {
    if (!amountRequested) {
      setAmountRequested(state?.offersResponse?.products?.[0]?.limits?.maximum);
    }
  }, [state?.offersResponse?.products?.[0]?.limits]);

  const dispatchNewAmountRequestDebounced = useDebouncedCallback(
    async (value) => {
      const data = {
        amountRequested: value,
      };
      dispatch(actions.updateOffersPayload(data));
      setDisableInputs(true);
    },
    1000,
  );

  useEffect(() => {
    if (
      amountRequested !== state.offersPayload?.application?.amount_requested &&
      amountRequested <=
        state?.offersResponse?.products?.[0]?.limits?.maximum &&
      amountRequested >=
        state?.offersResponse?.products?.[0]?.limits?.minimum &&
      amountHasBeenManuallyChanged.current
    ) {
      handleLoadingState(true);
      dispatchNewAmountRequestDebounced(amountRequested);
    }
  }, [amountRequested]);

  useEffect(() => {
    setDisableInputs(false);
  }, [state?.offersResponse?.products?.[0]?.offers]);

  return (
    <div className="w-full">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="grid grid-cols-3 gap-4 tablet:gap-4"
      >
        <h4 className="text-core-7 text-sm col-span-3 mb-8">
          <ParsedText htmlString={t('yd-step-1-label')} />
        </h4>
        <div
          className={`mt-3 mb-4 col-span-3 ${
            !state?.offersResponse?.products?.[0]?.limits?.maximum
              ? 'bg-core-3 animate-pulse'
              : ''
          }`}
        >
          <Slider
            {...{
              min: state?.offersResponse?.products?.[0]?.limits?.minimum || 0,
              max: state?.offersResponse?.products?.[0]?.limits?.maximum || 0,
            }}
            onChange={(value) => {
              handleAmountRequestedChange(`${value}`);
            }}
            value={amountRequested}
            ariaLabel={t('pp-slide-label')}
            step={getSliderStepIncrement()}
            className={
              !state?.offersResponse?.products?.[0]?.limits?.maximum
                ? 'opacity-0'
                : ''
            }
            disabled={disableInputs}
          />
        </div>
        <div className="-mt-2 tablet:-mt-5 ">
          <CurrencyInput
            currencySymbol={partnerSettings.currencySymbol}
            value={`${amountRequested}`}
            onChange={(value: string) => handleAmountRequestedChange(value)}
            min={state?.offersResponse?.products?.[0]?.limits?.minimum || 0}
            max={state?.offersResponse?.products?.[0]?.limits?.maximum || 0}
            error={showInputError}
            disabled={disableInputs}
          />
        </div>
        <div
          className={`col-span-2 tablet:col-span-2 text-xs text-core-7 content-center relative -top-[10px] ${
            !state?.offersResponse?.products?.[0]?.limits?.maximum
              ? 'bg-core-3 animate-pulse !text-core-3 rounded'
              : ''
          }`}
        >
          <span className="block text-right lmobile:inline lmobile:text-left">
            Min / Max:{' '}
          </span>
          <span className="block text-right lmobile:inline lmobile:text-left">
            <Amount intlConfig={intlConfig}>
              {state?.offersResponse?.products?.[0]?.limits?.minimum}
            </Amount>{' '}
            -{' '}
            <Amount intlConfig={intlConfig}>
              {state?.offersResponse?.products?.[0]?.limits?.maximum}
            </Amount>
          </span>
        </div>
        <h4
          className={`text-core-7 text-sm col-span-3 mt-14 ${
            !state?.offersResponse?.products?.[0]?.limits?.maximum
              ? 'bg-core-3 animate-pulse !text-core-3 rounded'
              : ''
          }`}
        >
          <ParsedText htmlString={t('yd-step-2-label')} />
        </h4>
        <div className="sr-only" aria-live="polite">
          {isPending ? 'Please loading quotes' : ''}
        </div>

        <ul
          className="col-span-3 grid grid-col-1 tablet:grid-cols-3 gap-8 desktop:gap-16"
          aria-hidden={isPending}
        >
          {state?.offersResponse?.products?.[0]?.offers?.map(
            (offer: any, index: number) => {
              return (
                <li key={offer.offer_id ?? index}>
                  <QuoteSelectButton
                    selected={offer.offer_id === state.offerSelected?.offer_id}
                    title={offer.description ?? ''}
                    split={offer.split_percentage ?? ''}
                    fee={offer?.repayment_amount - offer?.funded_amount ?? ''}
                    isPending={isPending}
                    onClick={() => onSelectOffer(offer.offer_id)}
                    totalNumberOfOffers={
                      state.offersResponse.products[0].offers.length
                    }
                    index={index}
                  />
                </li>
              );
            },
          )}
        </ul>
      </form>
    </div>
  );
};

export { QuoteEditor };
