import { useAnalytics } from '@/app/components/Analytics';
import { Tooltip } from 'react-tooltip';
import { ParsedText } from '../ParsedText';

export interface InfoTipProps {
  analyticEventPayload: {
    partner: string;
    tooltipId: string;
    fieldName: string;
  };
  label?: string | React.ReactElement;
  content: string | React.ReactElement;
  srOnlyLabel?: string;
  className?: string;
  fontSize?: string;
}

export const InfoTip = ({
  analyticEventPayload,
  label,
  content,
  srOnlyLabel,
  className,
  fontSize = '16px',
}: InfoTipProps) => {
  const analytics = useAnalytics();
  const anchorId = analyticEventPayload.fieldName + '-anchor';

  return (
    <div className={className ? className : 'relative w-full z-10'}>
      <a
        tabIndex={0}
        id={anchorId}
        className={`bg-transparent border-0 outline-none underline text-core-7 cursor-pointer ${
          typeof label === 'string'
            ? 'focus:bg-core-8 focus:text-core-1'
            : 'group'
        }`}
      >
        <span>{label}</span>
        {srOnlyLabel && <span className="sr-only">{srOnlyLabel}</span>}
      </a>
      <Tooltip
        anchorSelect={'#' + anchorId}
        place="bottom-end"
        opacity={1}
        openOnClick={true}
        openEvents={{ focus: true }}
        closeEvents={{ blur: true }}
        globalCloseEvents={{ escape: true }}
        afterShow={() =>
          analytics?.trackButtonClick(
            analyticEventPayload.partner,
            analyticEventPayload.tooltipId,
            analyticEventPayload.fieldName,
          )
        }
        style={{
          fontSize: fontSize,
          width: '100%',
          backgroundColor: 'white',
          color: 'black',
          padding: '1rem',
          borderRadius: '6px',
          textAlign: 'left',
          boxShadow: '0px 0px 12px rgba(14, 11, 35, 0.2)',
          zIndex: '999',
          lineHeight: '24px',
        }}
      >
        {typeof content === 'string' ? (
          <ParsedText htmlString={content} />
        ) : (
          content
        )}
      </Tooltip>
    </div>
  );
};
