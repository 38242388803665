'use-client';

import { Button } from '@/app/components/Button';
import actions from '@/app/contexts/global/actions';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { EventTypes, UserEvents, useLogEvent } from '@/app/hooks/useLogEvent';
import { useTranslation } from '@/app/i18n/client';
import {
  businessTypeValues,
  getBusinessTypeFamily,
} from '@/lib/businessTypeRoutes';
import Link from 'next/link';
import { ReactNode } from 'react';

export const SectionValues = ({
  editURL,
  isComplete,
  shareholderIndex,
  children,
}: {
  editURL: string;
  isComplete: boolean;
  shareholderIndex?: number;
  children: ReactNode;
}) => {
  const { t } = useTranslation();
  const { dispatch, state } = useGlobalStore();
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);

  const triggerEditMode = () => {
    dispatch(actions.editMode());
  };

  const deleteShareHolder = () => {
    logUserEvent(UserEvents.REMOVE_SHAREHOLDER);
    let newShareholderList: any = state.offersPayload.applicants;

    newShareholderList.splice(shareholderIndex, 1);
    dispatch(actions.updateOffersPayload({ applicants: newShareholderList }));
  };

  return (
    <>
      <p>
        {isComplete ? (
          <>
            {t('review-something-not-correct-label')}{' '}
            <Link
              href={editURL}
              className="whitespace-nowrap underline"
              onClick={triggerEditMode}
            >
              {t('review-something-not-correct-link')}
            </Link>
          </>
        ) : (
          <>
            {t('review-information-missing-label')}{' '}
            <Link
              href={editURL}
              className="whitespace-nowrap underline"
              onClick={triggerEditMode}
            >
              {t('review-add-details-link')}
            </Link>
          </>
        )}
      </p>
      {getBusinessTypeFamily(state?.offersPayload?.company?.business_type) ===
        businessTypeValues.limited_company && shareholderIndex ? (
        <p>
          <Button
            type="button"
            className="block whitespace-nowrap underline border-0"
            onclick={deleteShareHolder}
            id={`removeShareholder_${shareholderIndex}`}
            mixpanelButtonText={'removeShareholder'}
          >
            {t('review-delete-shareholder')}
          </Button>
        </p>
      ) : null}
      <div className="bg-core-2 rounded-md p-4 mb-8">{children}</div>
    </>
  );
};
