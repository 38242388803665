import React from 'react';
import { useTranslation } from '@/app/i18n/client';

export const PaymentsTab = ({
  split,
  currency,
}: {
  split: number;
  currency: string;
}) => {
  const { t } = useTranslation();

  const splitBackgroundStyle = {
    background:
      'repeating-linear-gradient(45deg, white, white 10px, #4164EA 10px, #4164EA 20px)',
  };

  const splitReferenceBackgroundStyle = {
    background:
      'repeating-linear-gradient(45deg, white, white 3px, #4164EA 3px, #4164EA 6px)',
  };

  const data = [
    { day: 1, totalSales: 1200 },
    { day: 2, totalSales: 1400 },
    { day: 3, totalSales: 0 },
    { day: 4, totalSales: 850 },
  ];

  const intlOptions: Intl.NumberFormatOptions = {
    currency: currency,
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
  };

  const maxHeight = 300;

  const renderBar = (totalSales: number) => {
    const payment = totalSales * (split / 100);
    const remaining = totalSales - payment;

    const totalHeight =
      totalSales > 0
        ? (totalSales / Math.max(...data.map((d) => d.totalSales))) *
          (maxHeight * 0.85)
        : 0;
    const paymentHeight =
      totalSales > 0 ? (payment / totalSales) * totalHeight : 0;
    const remainingHeight =
      totalSales > 0 ? (remaining / totalSales) * totalHeight : 0;

    return (
      <div
        className="flex flex-col-reverse w-10 mx-auto"
        style={{ height: `${maxHeight}px` }}
      >
        {totalSales ? (
          <>
            <div
              className="w-full bg-blue-600"
              style={{ height: `${remainingHeight}px` }}
            ></div>
            <div
              className="w-full"
              style={{
                height: `${paymentHeight}px`,
                ...splitBackgroundStyle,
              }}
            ></div>
            <p className="mt-2 mb-2 text-xs">
              {payment.toLocaleString(undefined, intlOptions)}
            </p>
          </>
        ) : (
          <div className="flex flex-col items-center text-xs text-center">
            <div className="mt-2 text-nowrap">
              <span>{t('pp-yfq-payments-no-sales')}</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col desktop:px-4 laptop:px-4 py-6 text-left">
      <h2 className="text-md-header-d mb-4 text-gray-800 justify-start">
        {t('pp-yfq-payments-heading')}
      </h2>
      <span className="text-xs text-gray-500">
        <p className="mb-2">{t('pp-yfq-payments-sub-heading1')}</p>
        <p className="mb-4">{t('pp-yfq-payments-sub-heading2')}</p>
      </span>

      <div className="flex flex-col items-left text-xs">
        <div className="flex items-center mb-4">
          <div
            className="w-4 h-4 rounded-full mr-2"
            style={{ ...splitReferenceBackgroundStyle }}
          ></div>
          <span className="text-gray-500">
            {t('pp-yfq-payments-you-pay', { split })}
          </span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-blue-600 rounded-full mr-2"></div>
          <span className="text-gray-500">{t('pp-yfq-payments-you-keep')}</span>
        </div>
      </div>

      <div className="flex justify-around">
        {data.map((item) => (
          <div key={item.day} className="text-center">
            {renderBar(item.totalSales)}
            <p className="text-xs mt-2 mb-2">
              {item.totalSales.toLocaleString(undefined, intlOptions)}
            </p>
            <p className="text-xs text-gray-500">{`${t(
              'pp-yfq-payments-day',
            )} ${item.day}`}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
