import { UserDetailsIcon } from '@/app/[lang]/[partner]/components/UserDetailsIcon';
import { SectionValues } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/SectionValues';
import IndividualDetails from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/PersonalDetails/IndividualDetails';
import { ReviewSection } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/ReviewSection';
import {
  businessTypeValues,
  getBusinessTypeFamily,
} from '@/lib/businessTypeRoutes';

export type ApplicantDetailsReviewSectionProps = {
  isComplete: boolean;
  editUrl: string;
  pageState: any;
};

export const ApplicantDetailsReviewSection = (
  props: ApplicantDetailsReviewSectionProps,
) => {
  const showPercentage_ownership =
    props.pageState?.offersPayload?.company?.business_type &&
    getBusinessTypeFamily(
      props.pageState?.offersPayload?.company?.business_type,
    ) !== businessTypeValues.sole_trader &&
    getBusinessTypeFamily(
      props.pageState?.offersPayload?.company?.business_type,
    ) !== businessTypeValues.partnership;

  return (
    <ReviewSection
      isComplete={props.isComplete}
      section="owner-details"
      icon={
        <span className="inline-block p-1 w-full h-full">
          <UserDetailsIcon />
        </span>
      }
      shareHolderPercentage={
        showPercentage_ownership &&
        props.pageState?.offersPayload?.applicants?.[0]?.ownership_percentage
      }
    >
      <SectionValues editURL={props.editUrl} isComplete={props.isComplete}>
        <dl>
          <IndividualDetails
            details={props.pageState?.offersPayload?.applicants?.[0]}
          />
        </dl>
      </SectionValues>
    </ReviewSection>
  );
};
