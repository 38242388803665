'use client';

import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useFeatureFlags } from '@/app/hooks/useFeatureFlags';
import { useUpdateLead } from '@/app/hooks/useUpdateLead';
import { useTranslation } from '@/app/i18n/client';
import { CompletedListItem } from '@/v2Components/CompletedListItem';
import { PageLayout } from '@/v2Components/PageLayout';
import { useParams } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { TermsAndExplanationTabs } from '@/v2Components/TermsAndExplanationTabs';
import { EventTypes, UserEvents, useLogEvent } from '@/app/hooks/useLogEvent';
import { StepTracker } from '@/app/components/StepTracker';
import { applicationRoutePaths } from '@/v2Lib/pageroutes';
import { useJourney } from '@/v2Hooks/useJourney';

const ACCEPTED_DECISION = 'Accepted';
const REFERRED_DECISION = 'Referred';

export default function CompletedPageBca() {
  const { getCurrentStep } = useJourney(applicationRoutePaths.COMPLETE);

  const params: { partner: string } = useParams();
  const updateLead = useUpdateLead(params.partner);
  const featureFlags = useFeatureFlags();
  const enableLeadService = featureFlags['enable-lead-service'];
  const { t } = useTranslation();
  const { state } = useGlobalStore();
  const [offerDecision, setOfferDecision] = useState<string>('');
  const pageLoaded = useRef<boolean>(false);
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);

  useEffect(() => {
    const decision = state?.offersResponse?.products?.[0]?.decision;
    if (!decision) {
      // show error - ytd
    }
    setOfferDecision(decision);
  }, [state?.offersResponse?.products]);

  useEffect(() => {
    if (enableLeadService) {
      updateLead();
    }
  }, [enableLeadService]);

  const isApprovedDecision = offerDecision === ACCEPTED_DECISION;
  const isReferredDecision = offerDecision === REFERRED_DECISION;

  useEffect(() => {
    if (isApprovedDecision) {
      logUserEvent(UserEvents.APPLICATION_APPROVED);
    }

    if (isReferredDecision) {
      logUserEvent(UserEvents.APPLICATION_REFERRED);
    }
  }, [offerDecision]);

  return (
    <>
      <StepTracker partner={params.partner} stepName="v2_Complete" />
      <PageLayout partner={params.partner}>
        <PageLayout.Left
          currentStep={getCurrentStep()}
          numberOfSteps={5}
          heading={t('pp-application')}
          partner={params.partner}
          loading={pageLoaded.current}
        >
          <div className="flex-1 flex flex-col">
            <h3 className="text-core-8 text-lg pt-0 pb-0">
              {isApprovedDecision
                ? t('pp-complete-approved-heading')
                : t('pp-complete-referred-heading')}
            </h3>
            <p className="text-core-7 text-sm pt-4 pb-10">
              {isApprovedDecision
                ? t('pp-complete-approved-intro-copy')
                : t('pp-complete-referred-intro-copy')}
            </p>
            <h3 className="text-core-8 pt-0 pb-0 text-sm">
              {t('pp-complete-what-next-heading')}
            </h3>
            <ul className="relative mt-6">
              <CompletedListItem
                stepText={
                  isApprovedDecision
                    ? t('pp-complete-what-next-approved-step-1')
                    : t('pp-complete-what-next-referred-step-1')
                }
                isComplete={true}
              />
              <CompletedListItem
                stepText={
                  isApprovedDecision
                    ? t('pp-complete-what-next-approved-step-2')
                    : t('pp-complete-what-next-referred-step-2')
                }
              />
              <CompletedListItem
                stepText={
                  isApprovedDecision
                    ? t('pp-complete-what-next-approved-step-3')
                    : t('pp-complete-what-next-referred-step-3')
                }
                finalStep={true}
              />
            </ul>
          </div>
        </PageLayout.Left>

        <PageLayout.Right isLoading={false} hiddenOnMobile={true}>
          <TermsAndExplanationTabs acceptedOffer={true} />
        </PageLayout.Right>
      </PageLayout>
    </>
  );
}
