import * as React from 'react';
import { SVGProps } from 'react';

const BankDetailsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={32} height={32} rx={5} />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m16 6.667-8.333 4.166v3.334h16.666v-3.334L16 6.667ZM11 15H9.333v5.833H11V15Zm13.333 8.333v-1.666H7.667v1.666h16.666ZM13.5 15h1.667v5.833H13.5V15Zm5 0h-1.667v5.833H18.5V15Zm2.5 0h1.667v5.833H21V15ZM9.333 11.862v.637h13.334v-.637L16 8.53l-6.667 3.333Zm5.834-1.029a.833.833 0 1 0 1.666 0 .833.833 0 0 0-1.666 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default BankDetailsIcon;
