import { useTranslation } from 'react-i18next';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { EventTypes, useLogEvent, UserEvents } from '@/app/hooks/useLogEvent';
import actions from '@/app/contexts/global/actions';
import Link from 'next/link';
import {
  businessTypeValues,
  getBusinessTypeFamily,
} from '@/lib/businessTypeRoutes';
import { Button } from '@/app/components/Button';
import React from 'react';

export type ReviewSectionValuesProps = {
  editURL: string;
  isComplete: boolean;
  shareholderIndex?: number;
  children: React.ReactNode;
};

export const SectionValues = (props: ReviewSectionValuesProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useGlobalStore();
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);

  const triggerEditMode = () => {
    dispatch(actions.editMode());
  };

  const deleteShareHolder = () => {
    logUserEvent(UserEvents.REMOVE_SHAREHOLDER);
    let newShareholderList: any = state.offersPayload.applicants;

    newShareholderList.splice(props.shareholderIndex, 1);
    dispatch(actions.updateOffersPayload({ applicants: newShareholderList }));
  };

  return (
    <>
      <p>
        {props.isComplete ? (
          <Link
            href={props.editURL}
            className="whitespace-nowrap  text-core-7 text-sm"
            onClick={triggerEditMode}
          >
            {t('review-something-not-correct-link')}
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="inline"
            >
              <path
                d="M8.44531 5.8335L12.612 10.0002L8.44531 14.1668"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="stroke-core-7"
              />
            </svg>
          </Link>
        ) : (
          <>
            {t('review-information-missing-label')}{' '}
            <Link
              href={props.editURL}
              className="whitespace-nowrap underline"
              onClick={triggerEditMode}
            >
              {t('review-add-details-link')}
            </Link>
          </>
        )}
      </p>
      {getBusinessTypeFamily(state?.offersPayload?.company?.business_type) ===
        businessTypeValues.limited_company && props.shareholderIndex ? (
        <p>
          <Button
            type="button"
            className="block whitespace-nowrap underline border-0"
            onclick={deleteShareHolder}
            id={`removeShareholder_${props.shareholderIndex}`}
            mixpanelButtonText={'removeShareholder'}
          >
            {t('review-delete-shareholder')}
          </Button>
        </p>
      ) : null}
      <div className="bg-core-2 rounded-md p-4 mb-8">{props.children}</div>
    </>
  );
};
