'use client';

import { generateUniqueId } from '@/lib/utils/generateUniqueId';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'next/navigation';

import {
  ApiCalls,
  EventTypes,
  SLIs,
  UserEvents,
  useLogEvent,
} from '@/app/hooks/useLogEvent';
import { handleIntercomTrackEvent, trackedEvents } from '@/lib/utils/intercom';
import { useGlobalStore } from '@/app/contexts/global/globalContext';

export const kycCheck = async ({
  payload,
  partner,
  traceId,
  spanId,
  logApiCall,
}: {
  payload: any;
  partner: string;
  traceId: string;
  spanId: string;
  logApiCall: (requestName: string, traceId: string, spanId: string) => void;
}) => {
  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');
  const partnerURLHost = host_url?.replace('{partner}', partner);

  logApiCall(ApiCalls.KYC_CHECK, traceId, spanId);

  const response = await fetch(`${partnerURLHost}/api/${partner}/kyc-check`, {
    method: 'POST',
    headers: {
      'X-Datadog-Trace-Id': traceId,
      'X-Datadog-Parent-Id': spanId,
      'X-Datadog-Origin': 'local',
      'X-Datadog-Sampling-Priority': '1',
    },
    body: JSON.stringify(payload),
    cache: 'no-store',
  });

  if (response.ok) {
    const jsonResponse = await response.json();

    return jsonResponse;
  } else {
    throw new Error('KYC endpoint not ok');
  }
};

export const useKYCCheck = () => {
  const params: { partner: string } = useParams();

  const { state, dispatch } = useGlobalStore();

  const logApiCall = useLogEvent(EventTypes.API_CALL);
  const logError = useLogEvent(EventTypes.ERROR);
  const logSli = useLogEvent(EventTypes.SLI);
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);

  const traceId = typeof window !== 'undefined' ? generateUniqueId() : '';
  const spanId = typeof window !== 'undefined' ? generateUniqueId() : '';

  const kycParams = {
    partner: params.partner,
    traceId,
    spanId,
    logError,
    logApiCall,
  };

  return useMutation({
    mutationFn: ({ payload }: { payload: any }) => {
      const params = { payload, ...kycParams };
      return kycCheck(params);
    },
    onSuccess: (response: any) => {
      if (response.kycStatus) {
        logUserEvent(UserEvents.KYC_PASSED);
      } else {
        logUserEvent(UserEvents.KYC_FAILED);
      }
    },
    retry: (failureCount) => {
      if (failureCount >= 3) {
        logError(new Error('KYC endpoint not ok'), traceId, spanId);
        handleIntercomTrackEvent(trackedEvents.KYC_ERROR);
        return false;
      } else {
        logSli(SLIs.RETRYING_KYC, failureCount);
        return true;
      }
    },
  });
};
