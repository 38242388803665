export const TickIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 19 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M17.9275 2.65898L6.92754 13.659C6.72754 13.859 6.52754 13.959 6.22754 13.959C5.92754 13.959 5.72754 13.859 5.52754 13.659L0.527539 8.65898C0.127539 8.25898 0.127539 7.65898 0.527539 7.25898C0.927539 6.85898 1.52754 6.85898 1.92754 7.25898L6.22754 11.559L16.5275 1.25898C16.9275 0.858984 17.5275 0.858984 17.9275 1.25898C18.3275 1.65898 18.3275 2.25898 17.9275 2.65898Z"
      />
    </svg>
  );
};
