import { ShieldIcon } from '@/app/components/icons/Shield';

export const BankNote = ({
  header,
  text,
}: {
  header: string;
  text: string;
}) => {
  return (
    <div className="bg-primary/5 px-6 py-4 rounded-xl mb-6">
      <h3 className="text-sm text-primary mb-4 font-bold pl-7 relative">
        <span className="inline-block absolute -left-1 w-5 h-5 leading-6">
          <ShieldIcon />
        </span>
        {header}
      </h3>
      <p className="text-sm text-core-7">{text}</p>
    </div>
  );
};
