import z from 'zod';
import dayjs from 'dayjs';
import { businessTypeValues } from '../businessTypeRoutes';

const isPreviousDate = (inputDate: Date) => {
  if (dayjs(inputDate).isValid()) {
    const now = dayjs();
    const date = dayjs(inputDate);
    const year = date.year();
    const salesforceDateLimitYear = 1701;
    if (year < salesforceDateLimitYear) {
      return false;
    }
    const diff = now.diff(date);
    if (diff > 0) {
      return true;
    }
  }
  return false;
};

const isOlderThan16 = (inputDate: Date) => {
  if (dayjs(inputDate).isValid()) {
    const now = dayjs();
    const date = dayjs(inputDate);
    const year = date.year();
    const salesforceDateLimitYear = 1701;

    if (year < salesforceDateLimitYear) {
      return false;
    }
    const diff = now.diff(date, 'years');
    if (diff >= 16) {
      return true;
    }
  }
  return false;
};

export const addressSchema = z.object({
  line1: z.string().min(1),
  town_city: z.string().min(1),
  postcode: z.string().min(1),
  country: z.string().min(1),
  line2: z.string().optional(),
});

export const residenceSchema = z.object({
  address: addressSchema,
  moved_in_date: z.coerce.date().refine((val) => isPreviousDate(val)),
  residential_status: z.enum(['', 'Home Owner', 'Renting']).optional(),
});

export const applicantSchema = z.object({
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  email_address: z.string().email(),
  date_of_birth: z.coerce.date().refine((val) => isOlderThan16(val)),
  residences: z.array(residenceSchema),
  telephone_number: z.string().min(1),
});

export const applicantsSchema = z.array(applicantSchema);

export const applicationSchema = z.object({
  merchant_id: z.string(),
  intended_use_of_funds: z.enum([
    'Purchase Stock',
    'Purchase New Equipment',
    'Expand Business',
    'Cash Flow',
    'Payroll',
    'Taxes Due',
    'Refurbishment',
    'Other',
    'Marketing',
    'Employing Extra Staff',
    'Safety Net',
    'Consolidating',
  ]),
});

export const companySchema = z.object({
  legal_name: z.string().min(1),
  business_type: z.enum([
    businessTypeValues.sole_trader,
    businessTypeValues.sole_proprietor,
    businessTypeValues.partnership,
    businessTypeValues.limited_partnership,
    businessTypeValues.limited_company,
    businessTypeValues.llc,
    businessTypeValues.other_corporation,
    businessTypeValues.einzelkaufmann,
    businessTypeValues.gmbh,
    businessTypeValues.mbh,
    businessTypeValues.ug,
    businessTypeValues.gbr,
    businessTypeValues.gmbh_co_kg,
    businessTypeValues.kg,
    businessTypeValues.ohg,
  ]),

  business_start_date: z.coerce.date().refine((val) => isPreviousDate(val)),
  industry: z.enum([
    'Bar / Pub',
    'Car Sales / Garage Services',
    'Fashion Retail',
    'Food / Drink Shop',
    'General Store',
    'Hair & Beauty',
    'Healthcare',
    'Hotel / B&B',
    'Other Retailer',
    'Other Services',
    'Restaurant',
    'Trade Contractor',
  ]),
  registered_address: addressSchema,
});

// Limited company schema
export const ownershipPercentageSchema = z.coerce.number().max(100).min(1);

// US state schema
export const stateSchema = z.string().min(1);

export const monthlyRevenuesSchema = z
  .array(
    z.object({
      date: z.coerce.date().refine((val) => isPreviousDate(val)),
      amount: z.number().min(1),
      count: z.number().min(1),
      currency: z.string().min(3),
    }),
  )
  .min(1);
