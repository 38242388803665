export type AddressValueProps = {
  line1?: string;
  line2?: string;
  postcode?: string;
  town_city?: string;
  state?: string;
};

export const AddressValue = (props: AddressValueProps) => {
  return (
    <>
      {props?.line1 ? (
        <>
          {props?.line1}
          <br />
        </>
      ) : null}
      {props?.line2 ? (
        <>
          {props?.line2}
          <br />
        </>
      ) : null}
      {props?.town_city ? (
        <>
          {props?.town_city}
          <br />
        </>
      ) : null}
      {props?.state ? (
        <>
          {props?.state}
          <br />
        </>
      ) : null}
      {props?.postcode ? (
        <>
          {props?.postcode}
          <br />
        </>
      ) : null}
    </>
  );
};
