import { useEffect } from 'react';
import Modal from 'react-modal';
import './genericErrorModalStyles.css';
import { Button } from '@/app/components/Button';
import { ParsedText } from '@/app/components/ParsedText';

const GenericErrorModal = ({
  open,
  header,
  text,
  showButton = false,
  buttonProps,
  buttonText,
}: {
  open: boolean;
  header: string;
  text: string;
  showButton?: boolean;
  buttonText?: string;
  buttonProps?: any;
}) => {
  const cleanUpOnClose = () => {
    console.log('cleaning up');
    document.body.classList.remove('ReactModal__Body--open');
    document.getElementById('appRoot')?.removeAttribute('aria-hidden');
  };

  useEffect(() => {
    Modal.setAppElement('#appRoot');
  }, []);

  return (
    <Modal
      isOpen={open}
      className="genericErrorModal"
      overlayClassName="genericErrorModalOverlay"
      contentLabel="Error"
      onAfterClose={cleanUpOnClose}
    >
      <div
        className={`max-w-3xl mx-auto py-6 lmobile:pt-10 flex-1 flex flex-col top-0 group `}
      >
        <div className="flex-1 overflow-auto px-4 mobile:px-6 lmobile:px-10">
          <h2 className={`mb-2 mobile:mb-6 text-lg`}>{header}</h2>
          <p className="mb-4 mobile:mb-6 text-core-7">
            <span className="text-sm">
              <ParsedText htmlString={text} />
            </span>
          </p>

          {showButton && (
            <div className="w-full text-center mb-4">
              <Button {...buttonProps}>{buttonText}</Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export { GenericErrorModal };
