const Label = ({
  id,
  htmlFor,
  showSkeleton,
  children,
}: {
  id?: string;
  htmlFor: string;
  showSkeleton?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <label
      id={id}
      htmlFor={htmlFor}
      className="text-core-8 text-sm mb-2 inline-block group-[.loading]/main:animate-pulse group-[.loading]/main:bg-core-4 group-[.loading]/main:text-core-4 group-[.loading]/main:rounded-sm duration-100"
    >
      {children}
    </label>
  );
};

export { Label };
