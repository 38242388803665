import { useTranslation } from 'react-i18next';
import React from 'react';

export type ReviewSectionProps = {
  isComplete: boolean;
  section: string;
  children: React.ReactNode;
  icon: React.ReactNode;
  shareHolderPercentage?: number;
  shareholderIndex?: number | null;
};

export const ReviewSection = (props: ReviewSectionProps) => {
  const { t } = useTranslation();
  const skeletonCSS =
    'group-[.loading]/main:animate-pulse group-[.loading]/main:!bg-core-3 group-[.loading]/main:text-core-3 group-[.loading]/main:transition-all group-[.loading]/main:duration-200';
  return (
    <details className="w-full  leading-[3rem] bg-opacity-0 group faqDetails mb-2">
      <summary className="flex items-center cursor-pointer border-b border-core-4 leading-5 pt-1 pb-2 pr-4 select-none">
        <span
          className={`h-8 w-8  ${
            props.isComplete ? 'bg-primary' : 'bg-warning-1'
          } text-core-1 mr-4 rounded-md ${skeletonCSS}`}
        >
          {props.icon}
        </span>
        <span className={`flex-1 mr-4 ${skeletonCSS}`}>
          <div className="relative  text-sm">
            <span className="mr-2">
              {props.shareholderIndex === null ||
              props.shareholderIndex === undefined
                ? t(`pp-review-${props.section}-heading`)
                : t(`pp-review-${props.section}-heading`).replace(
                    '{index}',
                    props.shareholderIndex.toString(),
                  )}
              {props.shareHolderPercentage && (
                <span>
                  {` (${props.shareHolderPercentage}% ${t(
                    'review-share-amout-label',
                  )})`}
                </span>
              )}
            </span>
            <span
              className={`${
                props.isComplete ? 'text-success-1' : 'text-warning-1'
              } group-[.loading]/main:!text-core-3`}
            >
              {!props.isComplete && `(${t('review-incomplete-label')})`}
            </span>
          </div>
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 16 16"
          fill="none"
          className="transition-transform duration-200 group-open:rotate-180 group-[.loading]/main:hidden"
          aria-hidden
        >
          <path
            d="M8.00048 8.77873L11.3005 5.47873L12.2431 6.4214L8.00048 10.6641L3.75781 6.4214L4.70048 5.47873L8.00048 8.77873Z"
            className="fill-contrastTextOnPrimary"
          />
        </svg>
      </summary>
      {props.children}
    </details>
  );
};
