import KeyValuePairs from '../KeyValuePairs';
import dayjs from 'dayjs';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import { useTranslation } from '@/app/i18n/client';
import { AddressValue } from '../AddressValue';

const IndividualDetails = ({ details }: { details: any }) => {
  const { t } = useTranslation();
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);

  return (
    <>
      <KeyValuePairs
        label={t('review-applicant-name-label')}
        value={
          details?.first_name && details?.last_name
            ? `${details?.first_name} ${details?.last_name}`
            : '?'
        }
      />
      <KeyValuePairs
        label={t('review-applicant-phone-number-label')}
        value={details?.telephone_number ?? '?'}
      />
      <KeyValuePairs
        label={t('review-applicant-email-address-label')}
        value={details?.email_address ?? '?'}
      />
      <KeyValuePairs
        label={t('review-applicant-dob-label')}
        value={
          details?.date_of_birth
            ? dayjs(details?.date_of_birth)
                .format(partnerSettings.dateFormat)
                .toString()
            : '?'
        }
      />
      <KeyValuePairs
        label={t('review-ssn-label')}
        value={details?.identification_number}
      />
      <KeyValuePairs
        label={t('review-applicant-home-address-label')}
        value={
          details?.residences?.[0]?.address?.line1
            ? AddressValue(details?.residences?.[0]?.address)
            : '?'
        }
      />

      <KeyValuePairs
        label={t('review-applicant-date-moved-in-label')}
        value={
          details?.residences?.[0]?.moved_in_date
            ? dayjs(details?.residences?.[0]?.moved_in_date)
                .format(partnerSettings.dateFormatMonthYear)
                .toString()
            : '?'
        }
      />
    </>
  );
};

export default IndividualDetails;
