import { JourneyFlow } from '@/v2Lib/journeyFlow';
import { getJourneyPageFlexi } from '@/v2Utils/journey/Flows/Flexi/getJourneyPageFlexi';
import { getJourneyPageBCA } from '@/v2Utils/journey/Flows/BCA/getJourneyPageBCA';

export const getJourneyPageDispatcher = (
  originPage: string,
  flow: string,
): JSX.Element => {
  switch (flow) {
    case JourneyFlow.FLEXI:
      return getJourneyPageFlexi(originPage);
    case JourneyFlow.BCA:
      return getJourneyPageBCA(originPage);
    default:
      return <div />;
  }
};
