import { applicationRoutePaths } from '@/v2Lib/pageroutes';

import YourQuotePageFlexi from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/pages/YourQuotePageFlexi';
import ReviewPageFlexi from '../../../../v2/review/pages/ReviewPageFlexi';
import CompletedPageFlexi from '../../../../v2/complete/pages/CompletedPageFlexi';
import BankDetailsPageFlexi from '../../../../v2/banking-details/pages/BankDetailsPageFlexi';
import EditBankDetailsPageFlexi from '../../../../v2/(Edit)/edit-banking-details/pages/EditBankingDetailsPageFlexi';

export const getJourneyPageFlexi = (originPage: string): JSX.Element => {
  switch (originPage) {
    case applicationRoutePaths.REVIEW_URL:
      return <ReviewPageFlexi />;

    case applicationRoutePaths.YOUR_QUOTE_URL:
      return <YourQuotePageFlexi />;

    case applicationRoutePaths.BANKING_INFO:
      return <BankDetailsPageFlexi />;

    case applicationRoutePaths.COMPLETE:
      return <CompletedPageFlexi />;

    case applicationRoutePaths.EDIT_BANKING_DETAILS_URL:
      return <EditBankDetailsPageFlexi />;

    default:
      return <div />;
  }
};
