import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import FeeTabPlaygroundInputIcon from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/components/FeesTabPlaygroundInputIcon';
import FeeTabPlaygroundIcon from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/components/FeesTabPlaygroundIcon';
import { useTranslation } from '@/app/i18n/client';
import { useFlexiAccountOfferAdvanceAmount } from '@/v2Hooks/flexi/useFlexiAccountOfferAdvanceAmount';
import { NumericFormat } from 'react-number-format';
import { Slider } from '@/app/components/Slider';

interface FeesTabProps {
  accountSize?: number;
  currency?: string;
}

export const FeesTab = ({ accountSize, currency }: FeesTabProps) => {
  const { t } = useTranslation();

  const minAmount = 500;
  const maxAmount = accountSize ?? 0;

  const [selectedSliderAmount, setSelectedSliderAmount] =
    useState<number>(minAmount);
  const [selectedInputAmount, setSelectedInputAmount] =
    useState<number>(minAmount);

  useEffect(() => {
    setSelectedInputAmount(selectedSliderAmount);
    setSelectedAmount(selectedSliderAmount);
  }, [selectedSliderAmount]);

  useEffect(() => {
    if (
      selectedInputAmount &&
      selectedInputAmount >= minAmount &&
      selectedInputAmount <= maxAmount
    ) {
      setSelectedSliderAmount(selectedInputAmount);
      setSelectedAmount(selectedInputAmount);
      setIsIncorrectAmount(false);
    } else {
      setIsIncorrectAmount(true);
    }
  }, [selectedInputAmount]);

  const [selectedAmount, setSelectedAmount] = useState<number>(minAmount);
  const [debouncedSelectedAmount, { isPending: debounceIsPending }] =
    useDebounce(selectedAmount, 500);
  const [isIncorrectAmount, setIsIncorrectAmount] = useState<boolean>(false);

  const flexiAccountOfferAdvanceAmount = useFlexiAccountOfferAdvanceAmount(
    maxAmount,
    debouncedSelectedAmount,
  );

  const intlOptions: Intl.NumberFormatOptions = {
    currency: currency,
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 2,
  };

  return (
    <div className="flex flex-col desktop:px-4 laptop:px-4 py-6">
      <h2 className="text-md-header-d font-semibold mb-4 text-gray-800 justify-start">
        {t('pp-yfq-fees-heading')}
      </h2>
      <p className="text-sm text-gray-500 mb-6 text-left">
        {t('pp-yfq-fees-sub-heading')}
      </p>
      <hr className="my-2 border-gray-300 py-2" />
      <div className="flex justify-left items-center mb-4 gap-2 ">
        <span>
          <FeeTabPlaygroundIcon />
        </span>
        <span className="text-sm text-gray-500">{t('pp-yfq-fees-sim')}</span>
      </div>

      <div className="relative mb-6 pt-6">
        <Slider
          id="amount-slider"
          min={minAmount}
          max={maxAmount}
          value={selectedSliderAmount}
          onChange={(x) => setSelectedSliderAmount(x)}
          step={100}
          color="primary"
          ariaLabel={t('pp-yfq-fees-slider-label-sr-only')}
        />
      </div>

      <div className="flex flex-row flex-wrap justify-start items-center mb-6 gap-4">
        <div
          className={
            'flex min-w-[148px] laptop:max-w-40 desktop:max-w-40 mobile:w-[148px] items-center border border-blue-400 rounded p-2'
          }
        >
          <NumericFormat
            aria-label="Amount input"
            value={selectedInputAmount.toLocaleString()}
            onValueChange={({ floatValue }) =>
              setSelectedInputAmount(floatValue ?? 0)
            }
            prefix={`${getCurrencySymbol(intlOptions)} `}
            decimalScale={0}
            className={
              'text-gray-800 text-left focus:outline-none appearance-none bg-transparent flex-grow min-w-0'
            }
          />
          <span className="ml-2">
            <FeeTabPlaygroundInputIcon />
          </span>
        </div>
        <div className="flex text-xs text-gray-500">
          {t('pp-yfq-fees-minmax')}
          {': '}
          {minAmount.toLocaleString(undefined, {
            ...intlOptions,
            maximumFractionDigits: 0,
          })}
          {' - '}
          {maxAmount.toLocaleString(undefined, {
            ...intlOptions,
            maximumFractionDigits: 0,
          })}
        </div>
      </div>

      <small
        style={{
          color: 'red',
          visibility: isIncorrectAmount ? 'visible' : 'hidden',
        }}
      >
        {t('pp-yfq-fees-outside-min-max')}
      </small>

      <div className="text-left text-gray-800 pt-2">
        <hr className="my-2 border-gray-300" />
        <div className="flex justify-between py-2">
          <span>{t('pp-yfq-fees-amount')}</span>
          {isIncorrectAmount ||
          debounceIsPending() ||
          flexiAccountOfferAdvanceAmount.loading ? (
            <div className="animate-pulse h-6 w-40 bg-gray-300 rounded"></div>
          ) : (
            <span className="font-semibold">
              {(
                flexiAccountOfferAdvanceAmount?.data?.amount ?? 0
              ).toLocaleString(undefined, intlOptions)}
            </span>
          )}
        </div>
        <hr className="my-2 border-gray-300" />
        <div className="flex justify-between py-2">
          <span>{t('pp-yfq-fees-fee')}</span>
          {isIncorrectAmount ||
          debounceIsPending() ||
          flexiAccountOfferAdvanceAmount?.loading ? (
            <div className="animate-pulse h-6 w-40 bg-gray-300 rounded"></div>
          ) : (
            <span className="font-semibold">
              {flexiAccountOfferAdvanceAmount?.data?.fee?.toLocaleString(
                undefined,
                intlOptions,
              )}
            </span>
          )}
        </div>
        <hr className="my-2 border-gray-300" />
        <div className="flex justify-between py-2">
          <span>{t('pp-yfq-fees-total')}</span>
          {isIncorrectAmount ||
          debounceIsPending() ||
          flexiAccountOfferAdvanceAmount.loading ? (
            <div className="animate-pulse h-6 w-40 bg-gray-300 rounded"></div>
          ) : (
            <span className="font-semibold">
              {(
                (flexiAccountOfferAdvanceAmount?.data?.amount ?? 0) +
                (flexiAccountOfferAdvanceAmount?.data?.fee ?? 0)
              ).toLocaleString(undefined, intlOptions)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const getCurrencySymbol = (options: any) =>
  (0)
    .toLocaleString(undefined, options)
    .replace(/\d/g, '')
    .trim()
    .replace('.', '');
