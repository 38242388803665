import { generateUniqueId } from '@/lib/utils/generateUniqueId';
import axios, { AxiosInstance, isAxiosError } from 'axios';
import { ApiCalls, EventTypes, useLogEvent } from '../hooks/useLogEvent';

let axiosInstance: AxiosInstance;

const useLiberisAPI = () => {
  const logError = useLogEvent(EventTypes.ERROR);
  const logAPIEvent = useLogEvent(EventTypes.API_CALL);

  if (!axiosInstance) {
    axiosInstance = axios.create({
      get headers() {
        return {
          'Content-Type': 'application/json',
          'X-Datadog-Trace-Id': generateUniqueId(),
          'X-Datadog-Parent-Id': generateUniqueId(),
        };
      },
    });

    axiosInstance.interceptors.request.use((request) => {
      const { 'X-Datadog-Trace-Id': traceId, 'X-Datadog-Parent-Id': spanId } =
        request.headers;

      logAPIEvent(ApiCalls.INSTRUMENTED, traceId, spanId);
      return request;
    });

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (isAxiosError(error)) {
          const { response, request } = error;
          if (response) {
            switch (response.status) {
              case 401: {
                logAPIEvent(
                  ApiCalls.UNAUTHORISED,
                  response.config.headers['X-Datadog-Trace-Id'],
                  response.config.headers['X-Datadog-Parent-Id'],
                );
                break;
              }
              case 404: {
                logAPIEvent(
                  ApiCalls.NOT_FOUND,
                  response.config.headers['X-Datadog-Trace-Id'],
                  response.config.headers['X-Datadog-Parent-Id'],
                );
                break;
              }
              case 500: {
                logError(
                  new Error(response.statusText),
                  response.config.headers['X-Datadog-Trace-Id'],
                  response.config.headers['X-Datadog-Parent-Id'],
                );
                break;
              }
              default: {
                logError(
                  new Error('Unexpected API response'),
                  response.config.headers['X-Datadog-Trace-Id'],
                  response.config.headers['X-Datadog-Parent-Id'],
                );
                break;
              }
            }
          } else if (request) {
            if (request.status === 0) {
              return null;
            }
            logError(
              new Error(request.statusText),
              request.headers['X-Datadog-Trace-Id'],
              request.headers['X-Datadog-Parent-Id'],
            );
            return null;
          } else {
            logError(
              new Error(error.message),
              request.headers['X-Datadog-Trace-Id'],
              request.headers['X-Datadog-Parent-Id'],
            );
            return null;
          }
        }

        return Promise.reject(error);
      },
    );
  }

  return axiosInstance;
};

export { useLiberisAPI };
