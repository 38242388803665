import { applicationRoutePaths, routePaths } from '@/v2Lib/pageroutes';
import { businessTypeValues } from '@/lib/businessTypeRoutes';

export const getNextRouteFlexi = (originPage: string, state?: any): string => {
  switch (originPage) {
    case applicationRoutePaths.BUSINESS_DETAILS_PART_ONE_URL:
      return applicationRoutePaths.BUSINESS_DETAILS_PART_TWO_URL;

    case applicationRoutePaths.BUSINESS_DETAILS_PART_TWO_URL:
      return applicationRoutePaths.YOUR_DETAILS_PART_ONE_URL;

    case applicationRoutePaths.YOUR_DETAILS_PART_ONE_URL:
      return applicationRoutePaths.YOUR_DETAILS_PART_TWO_URL;

    case applicationRoutePaths.YOUR_DETAILS_PART_TWO_URL:
      if (state?.business_type === businessTypeValues.partnership) {
        return applicationRoutePaths.SHAREHOLDER_DETAILS_URL;
      }
      return applicationRoutePaths.YOUR_QUOTE_URL;

    case applicationRoutePaths.SHAREHOLDER_DETAILS_URL:
      if (
        state?.shareholderIndex &&
        state?.shareholderIndex > 0 &&
        state?.businessType !== businessTypeValues.partnership
      ) {
        return applicationRoutePaths.REVIEW_URL;
      } else if (state?.cancel) {
        return applicationRoutePaths.REVIEW_URL;
      }
      return applicationRoutePaths.YOUR_QUOTE_URL;

    case applicationRoutePaths.YOUR_QUOTE_URL:
      if (state?.redirectToBca === true) {
        return applicationRoutePaths.REVIEW_URL;
      }
      return applicationRoutePaths.BANKING_INFO;

    case applicationRoutePaths.BANKING_INFO:
      return applicationRoutePaths.REVIEW_URL;

    case applicationRoutePaths.REVIEW_URL:
      if (state?.addShareholder) {
        return applicationRoutePaths.SHAREHOLDER_DETAILS_URL;
      } else if (state?.editBusinessDetails) {
        return applicationRoutePaths.EDIT_BUSINESS_DETAILS_URL;
      } else if (state?.editPersonalDetails) {
        return applicationRoutePaths.EDIT_PERSONAL_DETAILS_URL;
      } else if (state?.editBankDetails) {
        return applicationRoutePaths.EDIT_BANKING_DETAILS_URL;
      }
      return applicationRoutePaths.COMPLETE;

    case applicationRoutePaths.EDIT_BUSINESS_DETAILS_URL:
      return applicationRoutePaths.REVIEW_URL;

    case applicationRoutePaths.EDIT_PERSONAL_DETAILS_URL:
      return applicationRoutePaths.REVIEW_URL;

    case applicationRoutePaths.EDIT_BANKING_DETAILS_URL:
      return applicationRoutePaths.REVIEW_URL;

    default:
      return routePaths.LANDING;
  }
};
