import { BusinessDetailsIcon } from '@/app/[lang]/[partner]/components/BusinessDetailsIcon';
import { SectionValues } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/SectionValues';
import CompanyDetails from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/CompanyDetails/CompanyDetails';
import { ReviewSection } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/ReviewSection';

export type CompanyDetailsReviewSectionProps = {
  isComplete: boolean;
  editUrl: string;
  pageState: any;
};

export const CompanyDetailsReviewSection = (
  props: CompanyDetailsReviewSectionProps,
) => {
  return (
    <ReviewSection
      isComplete={props.isComplete}
      section="business-details"
      icon={
        <span className="inline-block p-1 w-full h-full">
          <BusinessDetailsIcon />
        </span>
      }
    >
      <SectionValues editURL={props.editUrl} isComplete={props.isComplete}>
        <dl>
          <CompanyDetails
            details={props?.pageState?.offersPayload}
            state={props?.pageState}
          />
        </dl>
      </SectionValues>
    </ReviewSection>
  );
};
