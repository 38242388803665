import { isAxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { EventTypes, useLogEvent, UserEvents } from '@/app/hooks/useLogEvent';
import { useLiberisAPI } from '@/app/network/useLiberisApi';
import { ApiPath } from '@/app/network/ApiPath';
import { usePartner } from '@/lib/partners/usePartner/client';
import { FlexiAccountOfferAdvanceQuoteResponse } from '@/app/api/[partner]/flexi/types';

/**
 * @description Custom hook to fetch flexi "play around" offer values. No merchant id is required
 *
 * @returns {data, loading, error} The merchant data, loading state, and error state
 */
const useFlexiAccountOfferAdvanceAmount = (
  accountSize: number | null | undefined,
  advanceAmount: number | null | undefined,
) => {
  let userTraceId: string;
  let userSpanId: string;
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);
  const logError = useLogEvent(EventTypes.ERROR);
  const api = useLiberisAPI();
  const partner = usePartner();
  const { isFetching, data, error } = useQuery({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryKey: ['flexiAdvanceQuote', { accountSize, advanceAmount }],
    enabled: !!accountSize && !!advanceAmount,
    queryFn: async ({ signal }) => {
      try {
        const response = await api.get<FlexiAccountOfferAdvanceQuoteResponse>(
          `/api/${partner}/${ApiPath.FLEXI}/account-offer/advance-quote?accountSize=${accountSize}&advanceAmount=${advanceAmount}`,
          {
            headers: {},
            signal,
          },
        );

        ({
          'X-Datadog-Trace-Id': userTraceId,
          'X-Datadog-Parent-Id': userSpanId,
        } = response.config.headers);

        const offerResponse = {
          ...response.data,
          accountSize: Number(response.data.accountSize),
          amount: Number(response.data.amount),
          fee: Number(response.data.fee),
        };

        if (!offerResponse) {
          logUserEvent(UserEvents.ACTIVE_CUSTOMER_NO_MERCHANT_DATA);
          return null;
        }

        return offerResponse;
      } catch (error) {
        logError(error instanceof Error ? error : new Error('Unknown error'));

        if (isAxiosError(error)) {
          const { response, request } = error;
          if (response) {
            logError(
              new Error('Unexpected advance-quote response', { cause: error }),
              userTraceId,
              userSpanId,
            );
          } else if (request) {
            logError(new Error(request.statusText), userTraceId, userSpanId);
            return null;
          } else {
            if (request.statusText === 'abort') {
              // Aborting a request is not an error
              return null;
            }
            logError(new Error(error.message), userTraceId, userSpanId);
            return null;
          }
        } else if (error instanceof Error) {
          logError(error, userTraceId, userSpanId);
          return null;
        } else {
          logError(new Error('Unknown error'), userTraceId, userSpanId);
          return null;
        }
      }
    },
  });

  return {
    data,
    loading: isFetching,
    error,
  };
};

export { useFlexiAccountOfferAdvanceAmount };
