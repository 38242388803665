const Loader = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="animate-spin"
    >
      <circle
        cx="32"
        cy="32"
        r="29"
        className="stroke-core-4"
        strokeWidth="6"
      />
      <mask id="path-2-inside-1_3265_232191" className="fill-core-1">
        <path d="M32 -1.39876e-06C36.2023 -1.58245e-06 40.3635 0.827704 44.2459 2.43585C48.1283 4.044 51.6559 6.40111 54.6274 9.37258C57.5989 12.3441 59.956 15.8717 61.5641 19.7541C63.1723 23.6365 64 27.7977 64 32L58.016 32C58.016 28.5835 57.3431 25.2005 56.0357 22.0441C54.7282 18.8877 52.8119 16.0197 50.3961 13.6039C47.9803 11.1881 45.1123 9.27177 41.9559 7.96435C38.7995 6.65692 35.4165 5.984 32 5.984L32 -1.39876e-06Z" />
      </mask>
      <path
        d="M32 -1.39876e-06C36.2023 -1.58245e-06 40.3635 0.827704 44.2459 2.43585C48.1283 4.044 51.6559 6.40111 54.6274 9.37258C57.5989 12.3441 59.956 15.8717 61.5641 19.7541C63.1723 23.6365 64 27.7977 64 32L58.016 32C58.016 28.5835 57.3431 25.2005 56.0357 22.0441C54.7282 18.8877 52.8119 16.0197 50.3961 13.6039C47.9803 11.1881 45.1123 9.27177 41.9559 7.96435C38.7995 6.65692 35.4165 5.984 32 5.984L32 -1.39876e-06Z"
        className="fill-primary stroke-core-primary"
        strokeWidth="12"
        mask="url(#path-2-inside-1_3265_232191)"
      />
    </svg>
  );
};

export { Loader };
