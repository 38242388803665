import { ReviewSection } from '../ReviewSection';
import BankDetailsIcon from '@/app/[lang]/[partner]/components/BankDetailsIcon/BankDetailsIcon';
import KeyValuePairs from '../KeyValuePairs';
import { useTranslation } from '@/app/i18n/client';
import { SectionValues } from '../SectionValues';

type ReviewSectionBankingDetailsProps = {
  isComplete: boolean;
  editUrl: string;
  bankDetails: any;
};

export const BankingDetailsReviewSection = ({
  isComplete,
  editUrl,
  bankDetails,
}: ReviewSectionBankingDetailsProps) => {
  const { t } = useTranslation();

  return (
    <ReviewSection
      isComplete={isComplete}
      section="bank-details"
      icon={<BankDetailsIcon />}
    >
      <SectionValues editURL={editUrl} isComplete={isComplete}>
        <dl>
          <KeyValuePairs
            label={t('pp-review-bank-details-bank-name')}
            value={bankDetails?.bank_name ?? ''}
          />
          <KeyValuePairs
            label={t('pp-review-bank-details-account-number')}
            value={bankDetails?.account_number ?? ''}
          />
          <KeyValuePairs
            label={t('pp-review-bank-details-routing-number')}
            value={bankDetails?.routing_number ?? ''}
          />
        </dl>
      </SectionValues>
    </ReviewSection>
  );
};
