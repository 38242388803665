import { Button } from '@/app/components/Button';
import { Loader } from './Loader';
import { useTranslation } from '@/app/i18n/client';

const LoadingButton = ({
  type,
  onClick,
  mixPanelText,
  isLoading,
  label,
  id,
}: {
  mixPanelText: string;
  isLoading: boolean;
  type: 'button' | 'submit' | 'reset' | undefined;
  label: string;
  id: string;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Button
      id={id}
      type={type}
      onclick={() => onClick?.()}
      mixpanelButtonText={mixPanelText}
      isLoading={isLoading}
    >
      {!isLoading ? (
        <>{label}</>
      ) : (
        <>
          <div className="h-7 text-center w-full" aria-hidden>
            <Loader />
          </div>
          <span className="sr-only">{t('pp-loading-sr-only-message')}</span>
        </>
      )}
    </Button>
  );
};

export { LoadingButton };
