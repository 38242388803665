'use client';

import { Button } from '@/app/components/Button';
import actions from '@/app/contexts/global/actions';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useFeatureFlags } from '@/app/hooks/useFeatureFlags';
import { useUpdateLead } from '@/app/hooks/useUpdateLead';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { PageLayout } from '@/v2Components/PageLayout';
import { useGetOffers } from '@/v2Hooks/useGetOffers';
import { applicationRoutePaths } from '@/v2Lib/pageroutes';
import { useJourney } from '@/v2Hooks/useJourney';
import { isEmpty } from 'lodash';
import { useParams, useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { GenericErrorModal } from '@/v2Components/GenericErrorModal';
import { Loader } from '@/v2Components/Loader';
import { QuoteEditor } from '@/v2Components/QuoteEditor';
import { TermsAndExplanationTabs } from '@/v2Components/TermsAndExplanationTabs';
import { StepTracker } from '@/app/components/StepTracker';
import { useAnalytics } from '@/app/components/Analytics';

export default function YourQuotePageBca() {
  const { getNextRoute, getCurrentStep } = useJourney(
    applicationRoutePaths.YOUR_QUOTE_URL,
  );

  const params: { partner: string } = useParams();
  const updateLead = useUpdateLead(params.partner);
  const featureFlags = useFeatureFlags();
  const enableLeadService = featureFlags['enable-lead-service'];
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalStore();
  const router = useRouter();
  const getOffers = useGetOffers();
  const pageLoaded = useRef<boolean>(false);
  const [handlingNewAmountChange, setHandlingNewAmountChange] =
    useState<boolean>(false);
  const [continueError, setContinueError] = useState<boolean>(false);
  const [movingNext, setMovingNext] = useState<boolean>(false);
  const numberOfRequests = useRef<number>(0);
  const analytics = useAnalytics();

  const partnerSettings = getPartnerSettings(params.partner);

  useEffect(() => {
    //rest the state value of selectedOffers to undefined
    dispatch(actions.updateOfferSelected(undefined));

    if (state?.offersPayload && !getOffers.isPending) {
      getOffers.mutate({
        payload: state.offersPayload,
        numberOfRequests: numberOfRequests.current,
      });
      numberOfRequests.current += 1;
      setHandlingNewAmountChange(false);
    } else {
      console.log('no state found');
      // @todo nothing in state - where to redirect?
    }

    pageLoaded.current = true;
  }, [state?.offersPayload?.application?.amount_requested]);

  useEffect(() => {
    const email = state?.offersPayload?.applicants[0]?.email_address;
    if (email) {
      analytics?.setUserProperty('Email', email);
    }

    const merchantId = state?.offersPayload?.application?.merchant_id;
    if (merchantId) {
      analytics?.setUserProperty('Merchant Id', merchantId);
    }

    const companyName = state?.offersPayload?.company?.legal_name;
    if (companyName) {
      analytics?.setUserProperty('Company Name', companyName);
    }

    const decisionStatus = state?.offersResponse?.products?.[0]?.decision;
    if (decisionStatus) {
      analytics?.setUserProperty('Decision Status', decisionStatus);
    }
  }, [state?.offersPayload, state?.offersResponse]);

  const nextPage = () => {
    if (!state.offerSelected || isEmpty(state.offerSelected)) {
      setContinueError(true);
    } else {
      setMovingNext(true);
      router.push(getNextRoute());
    }
  };

  const selectOffer = (selectedOfferId: string) => {
    const selectedOffer = state?.offersResponse?.products?.[0].offers?.find(
      (offer: any) => {
        return offer.offer_id === selectedOfferId;
      },
    );
    dispatch(actions.updateOfferSelected(selectedOffer));
    setContinueError(false);
  };

  useEffect(() => {
    if (enableLeadService) {
      updateLead();
    }
  }, [enableLeadService]);

  return (
    <>
      <StepTracker partner={params.partner} stepName="v2_YourQuote" />
      <PageLayout partner={params.partner}>
        <PageLayout.Left
          currentStep={getCurrentStep()}
          numberOfSteps={5}
          heading={t('pp-yq-page-heading')}
          partner={params.partner}
          loading={pageLoaded.current}
          growContainer={false}
        >
          <GenericErrorModal
            open={state?.offersResponse?.body?.hasEmptyProductsArray}
            header={
              numberOfRequests.current !== 1
                ? t('pp-yd-no-offers-header')
                : t('pp-yd-declined-header')
            }
            text={
              numberOfRequests.current !== 1
                ? t('pp-yd-no-offers-copy').replace(
                    '{contactPhoneNumber}',
                    partnerSettings?.contactPhoneNumber ?? '',
                  )
                : t('pp-yd-declined-copy')
            }
            showButton={true}
            buttonText={
              numberOfRequests.current !== 1
                ? t('pp-yd-no-offers-button-copy')
                : t('pp-yd-declined-return-button-copy')
            }
            buttonProps={{
              implementAsAnchor: true,
              mixpanelButtonText: 'Declined return button',
              id: 'returnToPartnerAfterDeclined',
              href:
                numberOfRequests.current !== 1
                  ? applicationRoutePaths.REVIEW_URL
                  : partnerSettings.profileReturnLink,
            }}
          />

          {(getOffers.isError || state.offersResponse?.body?.hasConflict) && (
            <GenericErrorModal
              open={
                getOffers.isError || state?.offersResponse?.body?.hasConflict
              }
              header={
                state?.offersResponse?.body?.hasConflict
                  ? t('pp-get-offer-conflict-header')
                  : t('pp-get-offer-fail-generic-header')
              }
              text={
                state?.offersResponse?.body?.hasConflict
                  ? t('pp-get-offer-conflict-text').replaceAll(
                      '{contactEmail}',
                      partnerSettings?.contactEmail ?? '',
                    )
                  : t('pp-get-offer-fail-generic-text').replace(
                      '{contactPhoneNumber}',
                      partnerSettings?.contactPhoneNumber ?? '',
                    )
              }
              showButton={true}
              buttonText={t('pp-offer-fail-return-text')}
              buttonProps={{
                implementAsAnchor: true,
                mixpanelButtonText: 'unknown seller id return button',
                id: 'returnToPartner',
                href: partnerSettings.profileReturnLink,
              }}
            />
          )}

          <h3 className="text-core-8 text-lg pt-0 pb-0">
            {!pageLoaded.current
              ? t('pp-yq-loading-heading')
              : t('pp-yq-heading')}
          </h3>
          <p className="text-core-7 text-sm pt-4">
            {!pageLoaded.current && t('pp-yq-loading-copy')}
          </p>
          <div
            className={` flex  ${
              !pageLoaded.current
                ? 'flex-1 items-center justify-center min-h-[calc(100vh-80px)] w-full laptop:h-auto'
                : 'laptop:flex-1 justify-center  '
            }`}
          >
            {!pageLoaded.current ? (
              <div className="w-16 h-16 -translate-y-2/4 mx-auto">
                <Loader />
              </div>
            ) : (
              <QuoteEditor
                isPending={
                  getOffers.isPending ||
                  !state.offerSelected ||
                  isEmpty(state.offerSelected) ||
                  handlingNewAmountChange
                }
                onSelectOffer={selectOffer}
                handleLoadingState={setHandlingNewAmountChange}
              />
            )}
          </div>
          <div className="hidden laptop:block pt-8">
            {continueError ? (
              <p className="text-error-1 text-center">
                {t('pp-yd-coninuation-error-message')}
              </p>
            ) : null}

            <Button
              type="button"
              onclick={nextPage}
              mixpanelButtonText={'business detail 1 continue'}
              id="businessDetailStep1"
              isLoading={
                movingNext || getOffers.isPending || handlingNewAmountChange
              }
            >
              {!movingNext ? (
                t('pp-yd-select-quote-label')
              ) : (
                <>
                  <div className="h-7 text-center w-full" aria-hidden>
                    <Loader />
                  </div>
                  <span className="sr-only">
                    {t('pp-loading-sr-only-message')}
                  </span>
                </>
              )}
            </Button>
          </div>
        </PageLayout.Left>

        <PageLayout.Right
          isLoading={
            getOffers.isPending ||
            !pageLoaded.current ||
            handlingNewAmountChange
          }
        >
          <TermsAndExplanationTabs />
        </PageLayout.Right>
      </PageLayout>
    </>
  );
}
