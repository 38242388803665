import { useTranslation } from '@/app/i18n/client';
import { AvailableCapitalCard } from './AvailableCapitalCard';

export const YourFlexiQuote = ({
  currency,
  availableAmount,
  split,
  months,
}: {
  currency: string;
  availableAmount: number;
  split: number;
  months: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 w-full" data-testid="your-flexi-quote">
      <h3 className="text-core-8 text-lg pt-0 pb-0">{t('pp-yfq-heading')}</h3>

      <h4 className="text-core-7 pt-0 pb-0">{t('pp-yfq-sub-heading')}</h4>

      <div className="my-4">
        <AvailableCapitalCard
          currency={currency}
          availableCapital={availableAmount}
          months={months}
          split={split}
        />
      </div>
    </div>
  );
};
