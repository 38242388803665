'use client';

import actions from '@/app/contexts/global/actions';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import {
  ApiCalls,
  EventTypes,
  SLIs,
  useLogEvent,
} from '@/app/hooks/useLogEvent';
import { useLiberisAPI } from '@/app/network/useLiberisApi';
import { handleIntercomTrackEvent, trackedEvents } from '@/lib/utils/intercom';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useParams } from 'next/navigation';

export const useAcceptOffer = () => {
  const params: { partner: string } = useParams();
  const { dispatch } = useGlobalStore();

  const logApiCall = useLogEvent(EventTypes.API_CALL);
  const logError = useLogEvent(EventTypes.ERROR);
  const logSli = useLogEvent(EventTypes.SLI);

  const api = useLiberisAPI();

  return useMutation({
    mutationFn: async ({
      payload,
      offerId,
    }: {
      payload: any;
      offerId: string;
    }) => {
      let userTraceId: string;
      let userSpanId: string;

      try {
        const response = await api.post(
          `/api/${params.partner}/offers/${offerId}/accept`,
          payload,
        );

        ({
          'X-Datadog-Trace-Id': userTraceId,
          'X-Datadog-Parent-Id': userSpanId,
        } = response.config.headers);

        logApiCall(ApiCalls.ACCEPT_OFFER, userTraceId, userSpanId);

        const contractLinks = response?.data?.links?.contract_link ? 1 : 0;
        logSli(SLIs.CONTRACT_LINKS_RETURNED, contractLinks);

        return response.data;
      } catch (error: any) {
        if (isAxiosError(error)) {
          const { response, request } = error;
          if (response) {
            handleIntercomTrackEvent(trackedEvents.RBP_ACCEPT_OFFERS_ERROR);
            logError(new Error(`Accept response not ok`));
            throw new Error('Accept response not ok');
          }
        }
        logError(error);
        handleIntercomTrackEvent(trackedEvents.RBP_ACCEPT_OFFERS_ERROR);

        throw error;
      }
    },
    onSuccess: (offers: any) => {
      dispatch(actions.updateAcceptResponse(offers));
    },
    onError: (error: any) => {
      console.error('Failed to accept offer:', error);
    },
  });
};
