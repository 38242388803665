'use client';

import { FormEvent, useEffect, useRef, useState } from 'react';
import { useParams, useRouter } from 'next/navigation';
import { useTranslation } from '@/app/i18n/client';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useJourney } from '@/v2Hooks/useJourney';
import { useUpdateLead } from '@/app/hooks/useUpdateLead';
import { useFeatureFlags } from '@/app/hooks/useFeatureFlags';
import { applicationRoutePaths } from '@/v2Lib/pageroutes';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import {
  scrollToFirstError,
  validateForm,
} from '@/lib/utils/formValidationHelpers';
import { StepTracker } from '@/app/components/StepTracker';
import { PageLayout } from '@/v2Components/PageLayout';
import { SecureBanner } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/banking-details/components/SecureBanner/SecureBanner';
import actions from '@/app/contexts/global/actions';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { PageSubHeader } from '../../../components/PageSubHeader/PageSubHeader';
import BankDetailsForm from '../../../banking-details/components/BankDetailsForm/BankDetailsForm';
import { BankNote } from '../../../banking-details/components/BankNote/BankNote';
import { LoadingButton } from '../../../components/LoadingButton';
import { CancelButton } from '../../../components/CancelButton';
import { AvailableCapitalCardHero } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/components/AvaiableCapitalCardHero';

export default function EditBankingDetailsPageFlexi() {
  const { getNextRoute, getCurrentStep } = useJourney(
    applicationRoutePaths.EDIT_BANKING_DETAILS_URL,
  );
  const params: { partner: string } = useParams();
  const { t } = useTranslation();
  const router = useRouter();
  const { state, dispatch } = useGlobalStore();
  const updateLead = useUpdateLead(params.partner);
  const featureFlags = useFeatureFlags();
  const enableLeadService = featureFlags['enable-lead-service'];
  const partnerSettings = getPartnerSettings(params.partner);

  const pageLoaded = useRef(false);
  const [movingNext, setMovingNext] = useState(false);

  const [bankDetails, setBankDetails] = useState<{
    bankName: string;
    accountNumber: string;
    routingNumber: string;
  }>({
    bankName: state?.acceptPayloadBody?.bank_details?.bank_name ?? '',
    accountNumber: state?.acceptPayloadBody?.bank_details?.account_number ?? '',
    routingNumber: state?.acceptPayloadBody?.bank_details?.routing_number ?? '',
  });

  useEffect(() => {
    if (enableLeadService) updateLead();
  }, [enableLeadService]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const valid = validateForm(e.currentTarget, partnerSettings.country);
    if (valid) {
      const data = {
        bank_details: {
          account_number: bankDetails.accountNumber,
          bank_name: bankDetails.bankName,
          routing_number: bankDetails.routingNumber,
          account_type: 'NONE',
        },
      };
      dispatch(actions.updateAcceptPayloadBody(data));
      setMovingNext(true);
      router.push(getNextRoute());
    } else {
      scrollToFirstError();
    }
  };

  const handleBankDetailsFormChange = (details: {
    bankName: string;
    accountNumber: string;
    routingNumber: string;
  }) => {
    const newBankDetails = {
      bankName: details.bankName,
      accountNumber: details.accountNumber,
      routingNumber: details.routingNumber,
    };
    setBankDetails(newBankDetails);
  };

  const handleCancel = () => {
    router.push(getNextRoute());
  };

  return (
    <>
      <StepTracker partner={params.partner} stepName="v2_BankDetails" />
      <PageLayout partner={params.partner}>
        <PageLayout.Left
          currentStep={getCurrentStep()}
          numberOfSteps={5}
          heading={t('pp-bkd-page-heading')}
          partner={params.partner}
          loading={pageLoaded.current}
          footerHeadline={t('pp-footer-headline')}
        >
          <form
            className="flex-1 flex flex-col"
            onSubmit={handleSubmit}
            noValidate
          >
            <PageHeader text={t('pp-bkd-edit-heading')} />
            <PageSubHeader text={t('pp-bkd-flexi-sub-heading')} />
            <SecureBanner />
            <BankDetailsForm
              onChange={handleBankDetailsFormChange}
              bankName={bankDetails?.bankName}
              accountNumber={bankDetails?.accountNumber}
              routingNumber={bankDetails?.routingNumber}
            />
            <BankNote
              header={t('pp-bkd-flexi-please-note-header')}
              text={t('pp-bkd-flexi-please-note-text')}
            />
            <LoadingButton
              type="submit"
              mixPanelText={'edit bank details'}
              isLoading={movingNext}
              label={t('pp-bkd-edit-save-details')}
              id={'editBankDetails'}
            />
            <CancelButton onClick={handleCancel} />
          </form>
        </PageLayout.Left>
        <PageLayout.Right isLoading={false} hiddenOnMobile={true}>
          <AvailableCapitalCardHero />
        </PageLayout.Right>
      </PageLayout>
    </>
  );
}
