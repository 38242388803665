import { useTranslation } from '@/app/i18n/client';

const CancelButton = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="text-core-7 cursor-pointer font-heavy hover:underline mt-8"
      onClick={() => onClick?.()}
    >
      {t('pp-cancel')}
    </button>
  );
};

export { CancelButton };
