import * as React from 'react';
import { SVGProps } from 'react';

export const FeeTabPlaygroundInputIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#404A70"
      fillRule="evenodd"
      d="M2.5 17.5h3.535L17.233 6.302a.833.833 0 0 0 0-1.178l-2.358-2.358a.833.833 0 0 0-1.178 0L2.5 13.963V17.5ZM14.285 6.891l1.178-1.179-1.178-1.178-1.179 1.178 1.179 1.179Zm-1.178 1.177-1.178-1.178-7.762 7.761v1.179h1.178l7.762-7.762Z"
      clipRule="evenodd"
    />
  </svg>
);
export default FeeTabPlaygroundInputIcon;
