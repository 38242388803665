import { BusinessType } from '../types/BusinessType.types';
import {
  Applicant,
  Company,
  OffersPayload,
} from '../types/OffersPayload.types';
import {
  applicantSchema,
  applicationSchema,
  companySchema,
  monthlyRevenuesSchema,
  ownershipPercentageSchema,
  stateSchema,
} from '../validation/OffersPayloadValidation';
import {
  ValidateCompletedFlexiOffersPayload,
  ValidateCompletedOffersPayload,
} from '../types/ValidateCompletedOffersPayload.types';
import { BankDetails } from '@/lib/types/BankDetails.type';

function getZodErrorMessage(error: any) {
  const message = error.message;
  const parsedMessage = JSON.parse(message) as Array<{ message: string }>;
  const zodError = parsedMessage[0];
  if (!zodError) throw Error('Zod is broken');
  console.log(zodError.message);
}

export const isUserInputDataComplete = (
  country: string,
  businessType: BusinessType,
  payload: OffersPayload,
  indicativeQuoting: boolean,
) => {
  const { application, company, applicants } = payload;

  let completedPayloadObjects: ValidateCompletedOffersPayload = {
    application: false,
    company: false,
    applicants: [],
  };

  // Check application details
  completedPayloadObjects.application =
    applicationSchema.safeParse(application).success;

  // Check company details
  completedPayloadObjects.company = checkCompanyDetails(
    country,
    company,
    indicativeQuoting,
  );

  // Check applicant details
  if (businessType === 'Partnership' && applicants?.length === 1) {
    applicants.push({});
  }
  completedPayloadObjects.applicants = applicants!.map((applicant) => {
    return checkApplicantDetails(businessType, country, applicant);
  });

  return completedPayloadObjects;
};

export const isFlexiUserInputDataComplete = (
  country: string,
  businessType: BusinessType,
  payload: OffersPayload,
  indicativeQuoting: boolean,
  bankDetails: BankDetails,
): ValidateCompletedFlexiOffersPayload => {
  const validationResult = isUserInputDataComplete(
    country,
    businessType,
    payload,
    indicativeQuoting,
  );
  return {
    ...validationResult,
    bankDetails: checkBankDetails(bankDetails),
  };
};

const checkCompanyDetails = (
  country: string,
  company: Company | undefined,
  indicativeQuoting: boolean,
) => {
  let isCompanyComplete = companySchema.safeParse(company).success;

  if (!isCompanyComplete) {
    const { error }: any = companySchema.safeParse(company);
    getZodErrorMessage(error);
  }

  if (country == 'US') {
    isCompanyComplete =
      isCompanyComplete &&
      stateSchema.safeParse(company?.registered_address?.state).success;
  }

  if (indicativeQuoting) {
    isCompanyComplete =
      isCompanyComplete &&
      monthlyRevenuesSchema.safeParse(company?.monthly_revenues).success;
  }

  return isCompanyComplete;
};

const checkApplicantDetails = (
  businessType: BusinessType,
  country: string,
  applicant: Applicant,
) => {
  let isApplicantComplete = false;

  isApplicantComplete = applicantSchema.safeParse(applicant).success;

  if (!isApplicantComplete) {
    const { error }: any = applicantSchema.safeParse(applicant);
    getZodErrorMessage(error);
  }

  if (businessType == 'Limited Company') {
    isApplicantComplete =
      isApplicantComplete &&
      ownershipPercentageSchema.safeParse(applicant.ownership_percentage)
        .success;
  }

  if (country == 'US') {
    isApplicantComplete =
      isApplicantComplete &&
      stateSchema.safeParse(applicant?.residences?.[0].address?.state).success;
  }
  return isApplicantComplete;
};

const checkBankDetails = (bankDetails?: BankDetails) => {
  return (
    !!bankDetails?.bank_name &&
    !!bankDetails?.account_number &&
    !!bankDetails?.routing_number
  );
};
