'use client';

import { generateUniqueId } from '@/lib/utils/generateUniqueId';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'next/navigation';

import {
  ApiCalls,
  EventTypes,
  SLIs,
  UserEvents,
  useLogEvent,
} from '@/app/hooks/useLogEvent';
import { handleIntercomTrackEvent, trackedEvents } from '@/lib/utils/intercom';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import actions from '@/app/contexts/global/actions';
import { checkForResponseConflict } from '@/lib/utils/offerCheckers';
import { useAnalytics } from '@/app/components/Analytics';

export const fetchOffers = async ({
  payload,
  partner,
  traceId,
  spanId,
  logError,
  logSli,
  logApiCall,
}: {
  payload: any;
  partner: string;
  traceId: string;
  spanId: string;

  logError: (
    error: Error,
    traceId?: string | undefined,
    spanId?: string | undefined,
  ) => void;
  logSli: (sliName: string, sliValue: number) => void;
  logApiCall: (requestName: string, traceId: string, spanId: string) => void;
}) => {
  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');
  const partnerURLHost = host_url?.replace('{partner}', partner);

  try {
    logApiCall(ApiCalls.FETCH_OFFERS, traceId, spanId);

    console.log('calling next api: ', partnerURLHost);
    const response = await fetch(`${partnerURLHost}/api/${partner}/offers`, {
      method: 'POST',
      headers: {
        'X-Datadog-Trace-Id': traceId,
        'X-Datadog-Parent-Id': spanId,
        'X-Datadog-Origin': 'local',
        'X-Datadog-Sampling-Priority': '1',
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      handleIntercomTrackEvent(trackedEvents.RBP_GET_OFFERS_ERROR);
      logError(new Error(`Offers response not ok`), traceId, spanId);
      const errorResponse = { error: 'Offers Response not ok' }; // todo review error message
      return errorResponse;
    }
  } catch (error: any) {
    logError(error, traceId, spanId);
    handleIntercomTrackEvent(trackedEvents.RBP_GET_OFFERS_ERROR);
    return { error: error.message };
  }
};

export const useGetOffers = () => {
  const params: { partner: string } = useParams();
  let totalNumberOfRequests = 0;
  const { state, dispatch } = useGlobalStore();
  const analytics = useAnalytics();

  const logApiCall = useLogEvent(EventTypes.API_CALL);
  const logError = useLogEvent(EventTypes.ERROR);
  const logSli = useLogEvent(EventTypes.SLI);
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);

  const traceId = typeof window !== 'undefined' ? generateUniqueId() : '';
  const spanId = typeof window !== 'undefined' ? generateUniqueId() : '';

  const fetchOffersParams = {
    partner: params.partner,
    traceId,
    spanId,
    logError,
    logSli,
    logApiCall,
  };

  return useMutation({
    mutationFn: ({
      payload,
      numberOfRequests,
    }: {
      payload: any;
      numberOfRequests: number;
    }) => {
      const params = { payload, ...fetchOffersParams };

      totalNumberOfRequests = numberOfRequests;
      return fetchOffers(params);
    },
    onSettled: (offers: any) => {
      const alreadyHasAcceptedOffer = checkForResponseConflict(offers);
      const isEmptyResponse = offers?.body?.hasEmptyProductsArray;

      if (alreadyHasAcceptedOffer) {
        logUserEvent(UserEvents.ALREADY_ACCEPTED_OFFER);
      }

      logSli(SLIs.OFFERS_SEEN, offers?.products?.[0]?.offers?.length ?? 0);
      const amountRequested =
        state?.offersPayload?.application?.amount_requested;

      if (offers?.products?.[0]?.offers?.length >= 1) {
        // reorder the quote so highest percentage is first
        let orderedOffers = [...offers.products[0].offers].sort(
          (a: any, b: any) => {
            // while looping check to see if the requested amount and funding amoutn are the same if not send alert
            if (amountRequested && a.funded_amount !== amountRequested) {
              logSli(SLIs.NEAREST_PRICING_ENFORCED, 1);
            }

            if (a.split_percentage > b.split_percentage) {
              return -1;
            }
            if (a.split_percentage < b.split_percentage) {
              return 1;
            }
            return 0;
          },
        );

        if (orderedOffers.length > 3) {
          logSli(SLIs.MORE_THAN_3_OFFERS, orderedOffers.length);

          let middleItemIndex = Math.ceil(orderedOffers.length / 2);
          if (orderedOffers.length % 2 === 0) middleItemIndex += 1;

          orderedOffers = [
            orderedOffers[0],
            orderedOffers[middleItemIndex - 1],
            orderedOffers[orderedOffers.length - 1],
          ];
        }

        offers.products[0].offers = orderedOffers;
        dispatch(actions.updateOfferResponse(offers));
        analytics?.setUserProperty('Liberis Id', offers?.liberis_id);
        dispatch(actions.updateOfferSelected(offers.products[0].offers[0]));
        analytics?.trackApplicationDecisionStatus(params.partner, offers?.products?.[0]?.decision);
      } else {
        dispatch(actions.updateOfferResponse(offers));
        dispatch(actions.updateOfferSelected({}));

        if (!alreadyHasAcceptedOffer && !isEmptyResponse) {
          throw new Error('Get offers failed');
        }
      }
    },
  });
};
