import { useTranslation } from '@/app/i18n/client';

export const SecureDetails = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="text-sm font-semibold text-core-8 mb-3">
        {t('pp-bkd-secure-learn-more-details-title')}
      </h3>
      <ul className="list-disc pl-5 text-core-8">
        <li className="text-sm mb-2">
          {t('pp-bkd-secure-learn-more-details-l1')}
        </li>
        <li className="text-sm mb-2">
          {t('pp-bkd-secure-learn-more-details-l2')}
        </li>
      </ul>
    </>
  );
};
