'use client';

import { Amount, AmountProps } from '@/app/components/Amount';
import { Button } from '@/app/components/Button';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useJourney } from '@/v2Hooks/useJourney';
import { applicationRoutePaths } from '../../lib/pageroutes';
import { useRouter } from 'next/navigation';
import { InfoSymbol } from '@/app/components/icons/InfoSymbol';
import { InfoTip } from '@/app/components/InfoTip';

const ACCEPTED_DECISION = 'Accepted';

const TermsSection = ({
  acceptedOffer,
  allowEdit,
}: {
  acceptedOffer?: boolean;
  allowEdit?: boolean;
}) => {
  const { getNextRoute } = useJourney(applicationRoutePaths.YOUR_QUOTE_URL);

  const { t } = useTranslation();
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const { state } = useGlobalStore();
  const [selectedOffer, setSelectedOffer] = useState<any>(null);
  const [continueError, setContinueError] = useState<boolean>(false);
  const [offerType, setOfferType] = useState<string>('Referred');
  const router = useRouter();
  const intlConfig: AmountProps['intlConfig'] = {
    locale: partnerSettings.locale.replace('_', '-'),
    currency: partnerSettings.currency,
  };

  const nextPage = () => {
    if (!state.offerSelected || isEmpty(state.offerSelected)) {
      setContinueError(true);
    } else {
      router.push(getNextRoute());
    }
  };

  const editQuote = () => {
    router.push(applicationRoutePaths.YOUR_QUOTE_URL);
  };

  const skeletonCSS =
    'group-[.loading]/aside:animate-pulse group-[.loading]/aside:w-full group-[.loading]/aside:bg-core-3 group-[.loading]/aside:rounded group-[.loading]/aside:text-core-3';

  useEffect(() => {
    if (acceptedOffer) {
      setSelectedOffer(state?.acceptResponse?.offer);
    } else {
      setSelectedOffer(state?.offerSelected);
    }
    const decision = state?.offersResponse?.products?.[0]?.decision;
    setOfferType(decision ?? 'Referred');
  }, [state?.offerSelected, state?.acceptResponse, acceptedOffer]);

  return (
    <div className="flex-1 flex flex-col max-w-[700px] w-full mx-auto mb-0  bg-core-1 rounded-[20px] py-6 text-left relative overflow-visible">
      <h2 className={`text-base mb-4 px-6 ${skeletonCSS}`}>
        {t(
          acceptedOffer
            ? offerType === ACCEPTED_DECISION
              ? 'pp-approved-terms-header'
              : 'pp-referred-terms-header'
            : 'pp-yq-terms-header',
        )}
      </h2>
      <p className={`text-sm text-core-7 mb-8 px-6 text-pretty ${skeletonCSS}`}>
        {t(
          acceptedOffer
            ? offerType === ACCEPTED_DECISION
              ? 'pp-approved-terms-intro-copy'
              : 'pp-referred-terms-intro-copy'
            : 'pp-yq-terms-intro-copy',
        )}
      </p>
      <div className="flex-1 mb-4 w-full max-h-[180px] flex flex-col">
        <h3 className="bg-core-3 px-6 py-4 text-core-7 text-sm mb-5 laptop:mb-3">
          {t('pp-yq-payment-terms-header')}
        </h3>
        <dl className="laptop:max-w-[500px] my-auto grid gap-3 grid-cols-[1fr_100px] tablet:grid-cols-[1fr_150px] laptop:grid-cols-[1fr_100px] desktop:tablet:grid-cols-[1fr_175px] px-6  grid-row-[1fr_1fr]">
          <dt className="">{t('pp-yq-split-label')}</dt>
          <dd
            className={`font-bold ${skeletonCSS} ${
              !selectedOffer?.split_percentage
                ? 'animate-pulse w-full bg-core-3 rounded !text-core-3'
                : ''
            } text-right`}
          >
            {selectedOffer?.split_percentage}%
          </dd>
          <dt>
            <div className="flex gap-4 flex-row w-auto">
              <span className="tablet:whitespace-nowrap">
                {t('pp-yq-estimated-completion-label')}
              </span>
              <span className="flex-1">
                <InfoTip
                  analyticEventPayload={{
                    partner: partner,
                    tooltipId: 'Estimated completion on terms panel',
                    fieldName: 'estimatedCompletion',
                  }}
                  label={<InfoSymbol />}
                  srOnlyLabel={t(
                    'pp-estimated-completion-tooltip-sronly-label',
                  )}
                  content={t('pp-estimated-completion-tooltip-text')}
                  className="z-10"
                  fontSize="14px"
                ></InfoTip>
              </span>
            </div>
          </dt>
          <dd
            className={`font-bold ${skeletonCSS} ${
              !selectedOffer?.term_length
                ? 'animate-pulse w-full bg-core-3 rounded !text-core-3'
                : ''
            }  text-right`}
          >
            {`${selectedOffer?.term_length} ${t('pp-yd-month-label')}`}
          </dd>
        </dl>
      </div>
      <div className="flex-1 mb-8 w-full flex flex-col max-h-[180px]">
        <h3 className="bg-core-3 px-6 py-4 text-core-7 text-sm  mb-5 laptop:mb-3">
          {t('pp-yq-total-funding-amount-header')}
        </h3>
        <dl className="laptop:max-w-[500px] my-auto grid gap-3 grid-cols-[1fr_100px] tablet:grid-cols-[1fr_150px] laptop:grid-cols-[1fr_100px] desktop:tablet:grid-cols-[1fr_175px] grid-row-[1fr_1fr] px-6">
          <dt className="">{t('pp-yq-funding-amount-label')}</dt>
          <dd
            className={`font-bold ${skeletonCSS} ${
              !selectedOffer?.funded_amount
                ? 'animate-pulse w-full bg-core-3 rounded !text-core-3'
                : ''
            } text-right`}
          >
            <Amount intlConfig={intlConfig}>
              {selectedOffer?.funded_amount}
            </Amount>
          </dd>
          <dt>
            <div className="flex gap-4 flex-row w-auto">
              <span className="">{t('pp-yq-fixed-fee-label')}</span>
              <span className="flex-1">
                <InfoTip
                  analyticEventPayload={{
                    partner: partner,
                    tooltipId: 'Fixed fee on terms panel',
                    fieldName: 'fixedFee',
                  }}
                  label={<InfoSymbol />}
                  srOnlyLabel={t('pp-fixed-fee-tooltip-sronly-label')}
                  content={t('pp-fixed-fee-tooltip-text')}
                  className="z-10"
                  fontSize="14px"
                ></InfoTip>
              </span>
            </div>
          </dt>
          <dd
            className={`font-bold ${skeletonCSS} ${
              !selectedOffer?.repayment_amount
                ? 'animate-pulse w-full bg-core-3 rounded !text-core-3'
                : ''
            } text-right`}
          >
            <Amount intlConfig={intlConfig}>
              {selectedOffer?.repayment_amount - selectedOffer?.funded_amount}
            </Amount>
          </dd>
        </dl>
      </div>
      <div className="border border-1 border-l-0 border-r-0 border-b-0 border-core-4 pt-8 w-full  max-h-[200px]">
        <dl className="laptop:max-w-[500px] grid gap-4 grid-cols-[1fr_100px] tablet:grid-cols-[1fr_150px] laptop:grid-cols-[1fr_100px] desktop:tablet:grid-cols-[1fr_175px] px-6">
          <dt className="">{t('pp-yq-total-total-label')}</dt>
          <dd
            className={`font-bold ${skeletonCSS} ${
              !selectedOffer?.repayment_amount
                ? 'animate-pulse w-full bg-core-3 rounded !text-core-3'
                : ''
            } text-right`}
          >
            <Amount intlConfig={intlConfig}>
              {selectedOffer?.repayment_amount}
            </Amount>
          </dd>
        </dl>
      </div>
      {allowEdit ? (
        <button
          type="button"
          onClick={editQuote}
          className="mt-8 hover:underline text-core-7"
        >
          {t('pp-yq-terms-edit-quote')}
        </button>
      ) : null}
      <div className="laptop:hidden mt-16 mb-8 px-5">
        {continueError ? (
          <p className="text-error-1 text-center">
            {t('pp-yd-coninuation-error-message')}
          </p>
        ) : null}
        {!acceptedOffer && (
          <Button
            type="button"
            onclick={nextPage}
            id="yd-continue-mobile"
            mixpanelButtonText="offer-selected-on-mobile"
          >
            {t('pp-yd-select-quote-label')}
          </Button>
        )}
      </div>
    </div>
  );
};

export { TermsSection };
