import { useTranslation } from '@/app/i18n/client';
import { usePartner } from '@/lib/partners/usePartner/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { Amount, AmountProps } from '@/app/components/Amount';
import { translateDropdownLabels } from '@/lib/utils/translateDropdownLabels';
import KeyValuePairs from '../KeyValuePairs';
import dayjs from 'dayjs';
import { AddressValue } from '../AddressValue';

const CompanyDetails = ({ details, state }: { details: any; state: any }) => {
  const { t } = useTranslation();
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);

  const intlConfig: AmountProps['intlConfig'] = {
    locale: partnerSettings.locale.replace('_', '-'),
    currency: partnerSettings.currency,
  };

  const businessSectorOptions = translateDropdownLabels(
    partnerSettings.businessSectorOptions,
    t,
  );
  const industry =
    businessSectorOptions.find(
      (option) => option.value === state?.expandedIndustry,
    )?.label ??
    state?.expandedIndustry ??
    details?.company?.industry ??
    '?';

  return (
    <>
      <KeyValuePairs
        label={t('review-business-entity-label')}
        value={details?.company?.business_type ?? '?'}
      />
      <KeyValuePairs
        label={t('review-company-name-label')}
        value={details?.company?.legal_name ?? '?'}
      />
      <KeyValuePairs
        label={t('review-business-start-date-label')}
        value={
          details?.company?.business_start_date
            ? dayjs(details?.company?.business_start_date)
                .format(partnerSettings.dateFormatMonthYear)
                .toString()
            : '?'
        }
      />

      {state?.offersPayload?.company?.monthly_revenues?.[0]?.amount && (
        <KeyValuePairs
          label={t('review-monthly-volumes-label')}
          value={
            state?.offersPayload?.company?.monthly_revenues?.[0]?.amount ? (
              <Amount intlConfig={intlConfig}>
                {state?.offersPayload?.company?.monthly_revenues?.[0]?.amount}
              </Amount>
            ) : (
              '?'
            )
          }
        />
      )}
      <KeyValuePairs
        label={t('review-company-address-label')}
        value={
          details?.company?.registered_address?.line1
            ? AddressValue(details?.company?.registered_address)
            : '?'
        }
      />
      <KeyValuePairs label={t('pp-industry')} value={industry} />
      <KeyValuePairs
        label={t('review-use-of-funds-label')}
        value={details?.application?.intended_use_of_funds ?? '?'}
      />
      {details?.company?.registration_number && (
        <KeyValuePairs
          label={t('pp-registration-number')}
          value={details.company.registration_number}
        />
      )}
    </>
  );
};

export default CompanyDetails;
