import { AvailableCapitalCard } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/components/AvailableCapitalCard';
import { useGlobalStore } from '@/app/contexts/global/globalContext';

export const AvailableCapitalCardHero = () => {
  const { state } = useGlobalStore();

  return (
    <aside
      className={`flex flex-col flex-1 text-center mx-auto overflow-auto box-content laptop:mt-12 laptop:max-h-[700px] laptop:max-w-[500px] laptop:px-14 laptop:pb-10 mobile:pb-4 mobile:px-4`}
      data-testid="fess-and-payments-tabs"
    >
      <AvailableCapitalCard
        currency={state.offersResponse?.currency}
        availableCapital={state.offersResponse?.accountSize}
        months={state.offersResponse?.availabilityPeriodMonths}
        split={state.offersResponse?.split}
      />
    </aside>
  );
};
