const KeyValuePairs = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => {
  return (
    <>
      <dt className="text-xs text-core-7">{label}</dt>
      <dd data-dd-privacy="mask" className="leading-6 mb-6 text-sm">
        {value}
      </dd>
    </>
  );
};

export default KeyValuePairs;
