import { useTranslation } from '@/app/i18n/client';
import { useState } from 'react';
import { FeesTab } from './FeesTab';
import { PaymentsTab } from './PaymentsTab';

const FeesAndPaymentsTabs = ({
  accountSize,
  split,
  currency,
}: {
  accountSize: number;
  split: number;
  currency: string;
}) => {
  const [activeSection, setActiveSection] = useState<'fees' | 'payments'>(
    'fees',
  );

  const { t } = useTranslation();

  const switchTab = (tab: 'fees' | 'payments') => {
    setActiveSection(tab);
  };

  return (
    <aside
      className={`bg-primary rounded-[0_0_0_0] laptop:rounded-[20px] flex flex-col flex-1 text-center group/tabs ${activeSection} overflow-auto box-content laptop:h-[670px] desktop:h-[670px] mobile:h-[675px] desktop:min-w-[375] laptop:min-w-[375px] laptop:max-w-[700px] desktop:mx-5 desktop:px-14 laptop:px-14 lmobile:px-4 mobile:px-4 smobile:px-4 desktop:pb-10 laptop:pb-10 lmobile:pb-10 mobile:pb-10 smobile:pb-10`}
      data-testid="fess-and-payments-tabs"
    >
      <p className="h-8 flex items-center justify-center opacity-95 text-white font-semibold my-4">
        {t('pp-yfq-learn-more')}
      </p>
      <div
        role="tablist"
        aria-orientation="horizontal"
        className="text-core-1 flex flex-row mx-auto w-full px-4 py-2 lmobile:px-10 laptop:px-5 max-w-[700px] laptop:max-w-[80%] text-base laptop:text-sm desktop:text-base"
      >
        <div className=" text-left ">
          <button
            role="tab"
            aria-selected={activeSection === 'fees'}
            id="fees-tab"
            aria-controls="fees-content-panel"
            onClick={() => switchTab('fees')}
            className={`tablet:whitespace-nowrap ${
              activeSection !== 'fees' ? 'opacity-95 font-light' : 'underline'
            }`}
          >
            {t('pp-yfq-fees')}
          </button>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="mx-4 border border-1 border-t-0 border-l-0 border-b-0 border-core-1 rotate-12" />
        </div>
        <div className="text-right">
          <button
            role="tab"
            aria-selected={activeSection === 'payments'}
            id="payments-tab"
            aria-controls="payments-content-panel"
            onClick={() => switchTab('payments')}
            className={`tablet:whitespace-nowrap ${
              activeSection !== 'payments'
                ? 'opacity-95 font-light'
                : 'underline'
            }`}
          >
            {t('pp-yfq-payments')}
          </button>
        </div>
      </div>
      <div className="bg-white rounded-[20px] mt-3  max-w-[700px] laptop:max-w-[650px] narrow:!max-w-[700px] narrow:!px-4 mx-auto min-h-[670px] lmobile:px-5 mobile:px-5 smobile:px-5">
        <div
          id="fees-content-panel"
          role="tabpanel"
          aria-labelledby="fees-tab"
          className="hidden group-[.fees]/tabs:flex lmobile:px-5 laptop:px-[max(10px,_1%)] flex-1 w-full mx-auto"
        >
          <FeesTab accountSize={accountSize} currency={currency} />
        </div>
        <div
          id="payments-content-panel"
          role="tabpanel"
          aria-labelledby="payments-tab"
          className="hidden group-[.payments]/tabs:flex lmobile:px-5 laptop:px-[max(10px,_1%)] flex-1 w-full mx-auto"
        >
          <PaymentsTab split={split} currency={currency} />
        </div>
      </div>
    </aside>
  );
};

export { FeesAndPaymentsTabs };
