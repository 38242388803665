import { CompletedListItem } from '../../components/CompletedListItem';
import Image from 'next/image';
import { useTranslation } from '@/app/i18n/client';

export const CompleteStepsFlexi = () => {
  const { t } = useTranslation();

  return (
    <div className="flex-1 flex flex-col" data-testid="flexi-complete-steps">
      <h3 className="text-core-8 text-lg pt-0 pb-0">{t('pp-yfc-heading')}</h3>
      <p className="text-core-7 text-sm pt-4 pb-10">
        {t('pp-yfc-sub-heading')}
      </p>
      <ul className="relative mt-2">
        <CompletedListItem stepText={t('pp-yfc-step1')} isComplete={true} />
        <CompletedListItem stepText={t('pp-yfc-step2')} />
        <CompletedListItem stepText={t('pp-yfc-step3')} finalStep={true} />
      </ul>
      <div className="flex ml-12 mt-6">
        <Image
          src={
            'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/liberis/ace-dashboard.svg'
          }
          alt="ACE Dashboard"
          width={500}
          height={500}
        />
      </div>
    </div>
  );
};
