import { GenericErrorModal } from '@/v2Components/GenericErrorModal';
import { useTranslation } from 'react-i18next';

export type KycErrorModalProps = {
  show: boolean;
  contactPhoneNumber?: string;
  profileReturnLink?: string;
};

export const KycErrorModal = (props: KycErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <GenericErrorModal
      open={props.show}
      header={t('pp-kyc-fail-generic-header')}
      text={t('pp-kyc-fail-generic-text').replace(
        '{contactPhoneNumber}',
        props?.contactPhoneNumber ?? '',
      )}
      showButton={true}
      buttonText={t('pp-kyc-return-text')}
      buttonProps={{
        implementAsAnchor: true,
        mixpanelButtonText: 'unknown seller id return button',
        id: 'returnToPartner',
        href: props?.profileReturnLink ?? '',
      }}
    />
  );
};
