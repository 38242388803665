import { GenericErrorModal } from '@/v2Components/GenericErrorModal';
import { useTranslation } from 'react-i18next';

export type AccountOfferErrorModalProps = {
  show: boolean;
  contactPhoneNumber?: string;
  profileReturnLink?: string;
};

export const AccountOfferErrorModal = (props: AccountOfferErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <GenericErrorModal
      open={props.show}
      header={t('pp-get-offer-fail-generic-header')}
      text={t('pp-get-offer-fail-generic-text').replace(
        '{contactPhoneNumber}',
        props?.contactPhoneNumber ?? '',
      )}
      showButton={true}
      buttonText={t('pp-offer-fail-return-text')}
      buttonProps={{
        implementAsAnchor: true,
        mixpanelButtonText: 'Your quote endpoint error return button',
        id: 'returnToPartner',
        href: props?.profileReturnLink ?? '',
      }}
    />
  );
};
