import { Chart } from '@/app/[lang]/[partner]/components/Chart';
import { Amount, AmountProps } from '@/app/components/Amount';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import { useEffect, useState } from 'react';

const ExplanationSection = () => {
  const [split, setSplit] = useState<number>(10);
  const [towardsBalance, setTowardsBalance] = useState<number>(10);
  const [amountToKeep, setAmountToKeep] = useState<number>(10);

  const { t } = useTranslation();
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const intlConfig: AmountProps['intlConfig'] = {
    locale: partnerSettings.locale.replace('_', '-'),
    currency: partnerSettings.currency,
  };
  const { state } = useGlobalStore();

  useEffect(() => {
    setSplit(state?.offerSelected?.split_percentage ?? 10);
    const amount = parseInt(
      t('pp-yq-daily-sales-content').replace(
        partnerSettings.currencySymbol,
        '',
      ),
      10,
    );
    const offBalance = amount * (state?.offerSelected?.split_percentage / 100);
    const keep = amount - offBalance;
    setTowardsBalance(offBalance);
    setAmountToKeep(keep);
  }, [state?.offerSelected?.split_percentage]);

  const skeletonCSS =
    'group-[.loading]/aside:animate-pulse group-[.loading]/aside:whitespace-nowrap group-[.loading]/aside:overflow-hidden group-[.loading]/aside:w-full group-[.loading]/aside:bg-core-3 group-[.loading]/aside:rounded group-[.loading]/aside:text-core-3';

  return (
    <div className="flex flex-col max-w-[700px] w-full mx-auto mb-12  bg-core-1 rounded-[20px] py-6 text-left overflow-auto">
      <h2 className="text-lg mb-4 px-6 ">
        {t('pp-yq-how-do-payments-work-header')}
      </h2>
      <p className="text-sm text-core-7 mb-2 px-6">
        {t('pp-yq-terms-intro-copy')}
      </p>
      <div className="flex-1 mx-auto content-center">
        <Chart
          splitPercentage={state?.offerSelected?.split_percentage}
          key={state?.offerSelected?.split_percentage}
        />
      </div>
      <div className="mb-6 w-full">
        <h3 className="bg-core-3 px-6 py-2 text-core-7 text-sm mb-4">
          {t('pp-yq-example-of-payments-header')}
        </h3>
        <dl className="grid gap-4 grid-cols-[1fr_100px] tablet:grid-cols-[1fr_150px] laptop:grid-cols-[1fr_100px] desktop:tablet:grid-cols-[1fr_175px] px-6">
          <dt className="">{t('pp-yq-daily-sales-label')}</dt>
          <dd className={`font-bold ${skeletonCSS}`}>
            {t('pp-yq-daily-sales-content')}
          </dd>
          <dt>{t('pp-yq-percentage-you-pay-label')}</dt>
          <dd className={`font-bold ${skeletonCSS}`}>
            {split}
            {t('pp-yq-percentage-you-pay-unit')}
          </dd>
        </dl>
      </div>

      <div className="w-full border border-1 border-l-0 border-r-0 border-b-0 border-core-4 pt-6 mb-4">
        <dl className="grid gap-4 grid-cols-[1fr_100px] tablet:grid-cols-[1fr_150px] laptop:grid-cols-[1fr_100px] desktop:tablet:grid-cols-[1fr_175px] px-6">
          <dt className="">{t('pp-yq-amount-paid-towards-balance-label')}</dt>
          <dd className={`font-bold ${skeletonCSS}`}>
            <Amount intlConfig={intlConfig}>{towardsBalance}</Amount>
          </dd>
          <dt>{t('pp-yq-amount-you-keep-label')}</dt>
          <dd className={`font-bold ${skeletonCSS}`}>
            <Amount intlConfig={intlConfig}>{amountToKeep}</Amount>
          </dd>
        </dl>
      </div>
    </div>
  );
};

export { ExplanationSection };
