import { TickIcon } from '@/app/components/icons/Tick';
import { ParsedText } from '@/app/components/ParsedText';

interface CompletedListItemProps {
  stepText: string;
  isComplete?: boolean;
  finalStep?: boolean;
}

export const CompletedListItem = ({
  stepText,
  isComplete,
  finalStep,
}: CompletedListItemProps) => (
  <li
    className={
      finalStep
        ? 'relative pl-6 mb-8'
        : "relative pl-6 mb-6 after:w-0.5 after:h-40 after:content-[''] after:block after:bg-core-4 after:absolute after:top-[18px] after:z-0 after:left-[11px]"
    }
  >
    <span
      className={`absolute z-[1] top-[6px] left-0 text-xs inline-block text-center leading-8 rounded-full w-6 h-6 border-2 
        ${
          isComplete
            ? 'text-core-1 p-1 bg-success-1 border-success-1'
            : 'bg-core-1 border-core-4'
        }
        ${
          finalStep &&
          "after:w-0.5  after:h-40 after:content-[''] after:block after:bg-core-1 after:absolute after:top-[22px] after:z-0 after:left-[9px]"
        }`}
    >
      {isComplete && <TickIcon />}
    </span>
    <span className="text-core-7 text-sm pl-9 pt-2 inline-block text-pretty max-w-96">
      <ParsedText htmlString={stepText} />
    </span>
  </li>
);
