import { AxiosError, isAxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { EventTypes, useLogEvent, UserEvents } from '@/app/hooks/useLogEvent';
import { useLiberisAPI } from '@/app/network/useLiberisApi';
import { ApiPath } from '@/app/network/ApiPath';
import { usePartner } from '@/lib/partners/usePartner/client';
import { FlexiAccountOfferResponse } from '@/app/api/[partner]/flexi/types';
import { useState } from 'react';

/**
 * @description Custom hook to fetch flexi offer data by merchant id. Will skip fetching if merchant id is null or undefined.
 *
 * @param externalId The partner client id of the merchant
 * @returns {data, loading, error} The merchant data, loading state, and error state
 */
const useFlexiAccountOffer = (externalId: string | null | undefined) => {
  const [status, setStatus] = useState<number | undefined>(undefined);
  let userTraceId: string;
  let userSpanId: string;
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);
  const logError = useLogEvent(EventTypes.ERROR);
  const api = useLiberisAPI();
  const partner = usePartner();
  const { isFetching, data, error } = useQuery({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryKey: ['flexiAccountOffer', { externalId }],
    enabled: !!externalId,
    queryFn: async ({ signal }) => {
      try {
        const response = await api.get<FlexiAccountOfferResponse>(
          `/api/${partner}/${ApiPath.FLEXI}/account-offer?externalId=${externalId}`,
          {
            headers: {},
            signal,
          },
        );

        ({
          'X-Datadog-Trace-Id': userTraceId,
          'X-Datadog-Parent-Id': userSpanId,
        } = response.config.headers);

        const offerResponse = response.data;

        if (!offerResponse) {
          logUserEvent(UserEvents.ACTIVE_CUSTOMER_NO_MERCHANT_DATA);
          return null;
        }

        return offerResponse;
      } catch (error) {
        logError(error instanceof Error ? error : new Error('Unknown error'));

        if (isAxiosError(error)) {
          const { response, request } = error;
          if (response) {
            setStatus(response.status);
            logError(
              new Error('Unexpected account-offer response', { cause: error }),
              userTraceId,
              userSpanId,
            );
          } else if (request) {
            logError(new Error(request.statusText), userTraceId, userSpanId);
            return null;
          } else {
            if (request.statusText === 'abort') {
              // Aborting a request is not an error
              return null;
            }
            logError(new Error(error.message), userTraceId, userSpanId);
            return null;
          }
        } else if (error instanceof Error) {
          logError(error, userTraceId, userSpanId);
          return null;
        } else {
          logError(new Error('Unknown error'), userTraceId, userSpanId);
          return null;
        }
      }
    },
  });

  return {
    data,
    loading: isFetching,
    error,
    status,
  };
};

export { useFlexiAccountOffer };
