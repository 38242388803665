export const routePaths = {
  LANDING: '/',
  ROOT: '',
};

export const applicationRoutePaths: { [key: string]: string } = {
  BUSINESS_DETAILS_PART_ONE_URL: `${routePaths.ROOT}`,
  BUSINESS_DETAILS_PART_TWO_URL: `${routePaths.ROOT}/business-details-two`,

  YOUR_DETAILS_PART_ONE_URL: `${routePaths.ROOT}/your-details`,
  YOUR_DETAILS_PART_TWO_URL: `${routePaths.ROOT}/your-details-two`,
  SHAREHOLDER_DETAILS_URL: `${routePaths.ROOT}/shareholder-details`,

  REVIEW_URL: `${routePaths.ROOT}/review`,
  EDIT_BUSINESS_DETAILS_URL: `${routePaths.ROOT}/edit-business-details`,
  EDIT_PERSONAL_DETAILS_URL: `${routePaths.ROOT}/edit-personal-details`,
  EDIT_BANKING_DETAILS_URL: `${routePaths.ROOT}/edit-banking-details`,

  YOUR_QUOTE_URL: `${routePaths.ROOT}/your-quote`,

  BANKING_INFO: `${routePaths.ROOT}/banking-details`,

  SIGN_CONTRACTS: `${routePaths.ROOT}/sign-contracts`,

  COMPLETE: `${routePaths.ROOT}/complete`,
};
