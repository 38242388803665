import { TFunction } from "i18next";

export const translateDropdownLabels = (options: {translationKey: string, value: string}[], t: TFunction<string, undefined>) => {
  const translatedOptions = options.map((option: {translationKey: string, value: string}) => {
    let translatedOption = {
      "label": t(option.translationKey),
      "value": option.value
    }
    return translatedOption;
  });
  return translatedOptions;
}
