import { useTranslation } from '@/app/i18n/client';
import { Label } from '../../../components/Label';
import { Input } from '@/app/components/Input';
import { EventTypes, useLogEvent, UserEvents } from '@/app/hooks/useLogEvent';
import { ChangeEvent, useEffect, useState } from 'react';
import { useInstitution } from '@/v2Hooks/useInstitution';

type BankDetailsFormProps = {
  bankName?: string;
  accountNumber?: string;
  routingNumber?: string;
  onChange: (details: {
    bankName: string;
    accountNumber: string;
    routingNumber: string;
  }) => void;
};

export default function BankDetailsForm({
  bankName: bankNameOriginal,
  accountNumber: accountNumberOriginal,
  routingNumber: routingNumberOriginal,
  onChange,
}: Readonly<BankDetailsFormProps>) {
  const { t } = useTranslation();
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);

  const { mutate: getInstitution } = useInstitution((value: any) => {
    if (value?.name) setBankName(value?.name);
  });

  const [bankName, setBankName] = useState(bankNameOriginal ?? '');
  const [accountNumber, setAccountNumber] = useState(
    accountNumberOriginal ?? '',
  );
  const [routingNumber, setRoutingNumber] = useState(
    routingNumberOriginal ?? '',
  );

  useEffect(() => {
    onChange({ bankName, accountNumber, routingNumber });
  }, [bankName, accountNumber, routingNumber]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { id, value, validity } = e.target;

    switch (id) {
      case 'bankName':
        setBankName(value);
        logUserEvent(UserEvents.MERCHANT_SET_BANK_NAME);
        break;
      case 'accountNumber':
        setAccountNumber(value);
        break;
      case 'routingNumber':
        if (validity.valid) {
          getInstitution(value);
        }
        setRoutingNumber(value);
        break;
    }
  };

  return (
    <div className="flex-1" data-testid="bank-details-form">
      <div className="grid grid-col-1 tablet:grid-col-2 gap-y-4 gap-x-8">
        <span>
          <Label htmlFor="accountNumber">{t('pp-bank-account-number')}</Label>
          <Input
            type="text"
            id="accountNumber"
            required
            value={accountNumber}
            onChange={handleChange}
            errorMessage={t('pp-bank-account-number-error-message')}
            placeholder={t('pp-bank-account-number-placeholder')}
            pattern='^.{6,17}'
          />
        </span>
        <span>
          <Label htmlFor="routingNumber">{t('pp-bank-routing-number')}</Label>
          <Input
            type="text"
            id="routingNumber"
            required
            value={routingNumber}
            onChange={handleChange}
            errorMessage={t('pp-bank-routing-number-error-message')}
            placeholder={t('pp-bank-routing-number-placeholder')}
            pattern="\d{9}"
          />
        </span>
        <span className="tablet:col-span-2">
          <Label htmlFor="bankName">{t('pp-bank-name')}</Label>
          <Input
            type="text"
            id="bankName"
            required
            value={bankName}
            onChange={handleChange}
            errorMessage={t('pp-bank-name-error-message')}
            placeholder={t('pp-bank-name-placeholder')}
          />
        </span>
      </div>
    </div>
  );
}
