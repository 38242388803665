import { GenericErrorModal } from '@/v2Components/GenericErrorModal';
import { useTranslation } from 'react-i18next';

export type ApplicationConflictErrorModalProps = {
  show: boolean;
  contactEmail?: string;
  profileReturnLink?: string;
};

export const ApplicationConflictErrorModal = (
  props: ApplicationConflictErrorModalProps,
) => {
  const { t } = useTranslation();

  return (
    <GenericErrorModal
      open={props.show}
      header={t('pp-get-offer-conflict-header')}
      text={t('pp-get-offer-conflict-text').replaceAll(
        '{contactEmail}',
        props?.contactEmail ?? '',
      )}
      showButton={true}
      buttonText={t('pp-offer-fail-return-text')}
      buttonProps={{
        implementAsAnchor: true,
        mixpanelButtonText: 'Application endpoint conflict error return button',
        id: 'returnToPartner',
        href: props?.profileReturnLink ?? '',
      }}
    />
  );
};
