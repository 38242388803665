import * as React from 'react';
import { SVGProps } from 'react';

const SecureIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={28}
    fill="none"
    {...props}
  >
    <path
      fill="#4164EA"
      d="M24 4.62v4.484c0 16.551-12 18.69-12 18.69S0 25.654 0 9.103V4.62C7.655 4.62 12 0 12 0s4.62 4.62 12 4.62Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M8.719 10.047v.578H7.563a.578.578 0 0 0-.579.578v6.938c0 .319.26.578.579.578h9.25c.319 0 .578-.26.578-.578v-6.938a.578.578 0 0 0-.578-.578h-1.157v-.578a3.469 3.469 0 1 0-6.937 0Zm-.578 7.515v-5.78h8.093v5.78H8.141Zm3.468-2.467a1.156 1.156 0 1 1 1.157 0v1.311h-1.157v-1.31Zm2.891-5.048v.578H9.875v-.578a2.312 2.312 0 1 1 4.625 0Z"
      clipRule="evenodd"
    />
  </svg>
);

export default SecureIcon;
