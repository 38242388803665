import { useEffect, useRef, useState } from 'react';
import { useTranslation } from '@/app/i18n/client';
import { SecureDetails } from './SecureDetails';
import SecureIcon from './SecureIcon';
import { InfoTip } from '@/app/components/InfoTip';
import { usePartner } from '@/lib/partners/usePartner/client';

export const SecureBanner = () => {
  const { t } = useTranslation();
  const partner = usePartner();

  return (
    <div
      className={
        'flex flex-nowrap desktop:items-center laptop:items-center mobile:items-start justify-start text-sm text-gray-600 py-6 gap-2'
      }
    >
      <div>
        <SecureIcon />
      </div>
      <div className={'flex flex-row flex-wrap gap-1 relative'}>
        <span>
          {`${t('pp-bkd-secure-note')} `}
          {
            <InfoTip
              analyticEventPayload={{
                partner: partner,
                tooltipId: 'Learn-more-about-security',
                fieldName: 'security-bank-details',
              }}
              content={<SecureDetails />}
              label={t('pp-bkd-secure-learn-more')}
              className="inline"
            ></InfoTip>
          }
        </span>
      </div>
    </div>
  );
};
