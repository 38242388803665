'use client';

import { StepTracker } from '@/app/components/StepTracker';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { PageLayout } from '@/v2Components/PageLayout';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useParams, useRouter } from 'next/navigation';
import { useJourney } from '@/v2Hooks/useJourney';
import { FeesAndPaymentsTabs } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/components/FeesAndPaymentsTab';
import { YourFlexiQuoteLoading } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/components/YourFlexiQuoteLoading';
import { YourFlexiQuote } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/components/YourFlexiQuote';
import { applicationRoutePaths } from '@/v2Lib/pageroutes';
import { useFlexiAccountOffer } from '@/v2Hooks/flexi/useFlexiAccountOffer';
import { useEffect, useMemo, useState } from 'react';
import actions from '@/app/contexts/global/actions';
import { ContinueButton } from '@/v2Components/ContinueButton';
import { AccountOfferErrorModal } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/components/AccountOfferErrorModal';
import { ApplicationConflictErrorModal } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/ApplicationConflictErrorModal';
import { QuoteRedirectToBcaModal } from '../components/QuoteRedirectToBcaModal';

dayjs.extend(customParseFormat);

export default function YourQuotePageFlexi() {
  const { state, dispatch } = useGlobalStore();
  const { getCurrentStep } = useJourney(applicationRoutePaths.YOUR_QUOTE_URL);
  const params: { partner: string } = useParams();

  const partnerSettings: any = getPartnerSettings(params.partner);

  const flexiAccountOffer = useFlexiAccountOffer(
    state?.offersPayload?.application?.merchant_id,
  );

  // TODO: This is a hardcoded value before we release, ideally it should be a config in the partnerSettings
  const moveToBca = useMemo(() => {
    return (flexiAccountOffer?.data?.accountSize ?? 0) >= 60000;
  }, [flexiAccountOffer.data]);

  useEffect(() => {
    if (!flexiAccountOffer.data) return;

    dispatch(actions.updateOfferResponse(flexiAccountOffer.data));
  }, [flexiAccountOffer.data]);

  const { t } = useTranslation();

  const { getNextRoute } = useJourney(applicationRoutePaths.YOUR_QUOTE_URL);
  const [movingNext, setMovingNext] = useState<boolean>(false);
  const router = useRouter();

  const nextPage = () => {
    setMovingNext(true);
    router.push(getNextRoute());
  };

  return (
    <>
      <StepTracker partner={params.partner} stepName="v2_YourQuote" />
      <PageLayout partner={params.partner}>
        <PageLayout.Left
          currentStep={getCurrentStep()}
          numberOfSteps={5}
          heading={t('pp-application')}
          partner={params.partner}
          loading={flexiAccountOffer.loading}
          footerHeadline={t('pp-footer-headline')}
        >
          {(() => {
            switch (true) {
              case flexiAccountOffer.loading:
                return <YourFlexiQuoteLoading />;
              case !!flexiAccountOffer.error:
                return flexiAccountOffer.status === 409 ? (
                  <ApplicationConflictErrorModal
                    show={true}
                    contactEmail={partnerSettings.contactEmail}
                    profileReturnLink={partnerSettings.profileReturnLink}
                  />
                ) : (
                  <AccountOfferErrorModal
                    show={true}
                    contactPhoneNumber={partnerSettings.contactPhoneNumber}
                    profileReturnLink={partnerSettings.profileReturnLink}
                  />
                );
              case moveToBca:
                return <QuoteRedirectToBcaModal show={true} />;
              case !!flexiAccountOffer.data:
                return (
                  <>
                    <div className="flex laptop:flex-1">
                      <YourFlexiQuote
                        currency={
                          flexiAccountOffer.data?.currency ??
                          partnerSettings.currency ??
                          'GBP'
                        }
                        availableAmount={
                          flexiAccountOffer.data?.accountSize ?? 0
                        }
                        months={
                          flexiAccountOffer.data?.availabilityPeriodMonths ?? 0
                        }
                        split={flexiAccountOffer.data?.split ?? 0}
                      />
                    </div>
                    <div className={'mt-10 hidden laptop:block'}>
                      <ContinueButton
                        id={'your-flexi-quote-continue-laptop'}
                        type="button"
                        mixPanelText="your flexi quote continue"
                        onClick={nextPage}
                        isLoading={movingNext}
                      />
                    </div>
                  </>
                );
              default:
                return <div />;
            }
          })()}
        </PageLayout.Left>

        <PageLayout.Right hiddenOnMobile={false}>
          {(() => {
            switch (true) {
              case flexiAccountOffer.loading:
                return <div />;
              case !!flexiAccountOffer.error:
                return <div />;
              case moveToBca:
                return <div />;
              case !!flexiAccountOffer.data:
                return (
                  <div
                    className={`flex flex-col flex-1 text-center laptop:max-w-[700px]`}
                  >
                    <FeesAndPaymentsTabs
                      accountSize={flexiAccountOffer.data?.accountSize}
                      currency={flexiAccountOffer.data?.currency}
                      split={flexiAccountOffer.data?.split}
                    />
                    <div className={'my-10 mx-4 block laptop:hidden'}>
                      <ContinueButton
                        id={'your-flexi-quote-continue-mobile'}
                        type="button"
                        mixPanelText="your flexi quote continue"
                        onClick={nextPage}
                        isLoading={movingNext}
                      />
                    </div>
                  </div>
                );
              default:
                return <div />;
            }
          })()}
        </PageLayout.Right>
      </PageLayout>
    </>
  );
}
