import { JourneyFlow } from '@/v2Lib/journeyFlow';
import { getNextRouteFlexi } from '@/v2Utils/journey/Flows/Flexi/getNextRouteFlexi';
import { getNextRouteBCA } from '@/v2Utils/journey/Flows/BCA/getNextRouteBCA';

export const getNextRouteDispatcher = (
  originPage: string,
  flow: string,
  state?: any,
): string => {
  switch (flow) {
    case JourneyFlow.FLEXI:
      return getNextRouteFlexi(originPage, state);
    case JourneyFlow.BCA:
      return getNextRouteBCA(originPage, state);
    default:
      return "";
  }
};
