import { applicationRoutePaths } from '@/v2Lib/pageroutes';

import YourQuotePageBca from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/your-quote/pages/YourQuotePageBca';
import CompletedPageBca from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/complete/pages/CompletedPageBca';
import ReviewPageBca from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/pages/ReviewPageBca';
import BankDetailsPageBca from '../../../../v2/banking-details/pages/BankDetailsPageBca';

export const getJourneyPageBCA = (originPage: string): JSX.Element => {
  switch (originPage) {
    case applicationRoutePaths.REVIEW_URL:
      return <ReviewPageBca />;

    case applicationRoutePaths.YOUR_QUOTE_URL:
      return <YourQuotePageBca />;

    case applicationRoutePaths.BANKING_INFO:
      return <BankDetailsPageBca />;

    case applicationRoutePaths.COMPLETE:
      return <CompletedPageBca />;

    default:
      return <div />;
  }
};
