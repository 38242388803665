import { useTranslation } from '@/app/i18n/client';
import { useState } from 'react';
import { TermsSection } from './TermsSection';
import { ExplanationSection } from './ExplanationSection';
import { Button } from '@/app/components/Button';
import { ParsedText } from '@/app/components/ParsedText';

const TermsAndExplanationTabs = ({
  acceptedOffer,
  allowEdit,
}: {
  acceptedOffer?: boolean;
  allowEdit?: boolean;
}) => {
  const [activeSection, setActiveSection] = useState<'terms' | 'explanation'>(
    'terms',
  );

  const { t } = useTranslation();

  const switchTab = (tab: 'terms' | 'explanation') => {
    setActiveSection(tab);
  };

  return (
    <aside
      className={`bg-primary rounded-[20px_20px_0_0] laptop:rounded-[20px] flex flex-col flex-1 text-center group/tabs ${activeSection} overflow-auto box-content laptop:max-w-[700px] desktop:mx-5 `}
    >
      <p className="laptop:hidden text-left pl-5 pt-6 text-core-1 text-sm font-bold max-w-[700px] mx-auto w-full">
        <ParsedText htmlString={t('pp-yd-step-3-label')} />
      </p>
      <div
        role="tablist"
        aria-orientation="horizontal"
        className="text-core-1 flex flex-row mx-auto mt-6 narrow:!mt-5 laptop:mt-12 mb-6 narrow:!mb-3 w-full px-4 lmobile:px-10 laptop:px-5 max-w-[700px] laptop:max-w-[80%] text-base laptop:text-sm desktop:text-base"
      >
        <div className=" text-left ">
          <button
            role="tab"
            aria-selected={activeSection === 'terms'}
            id="terms-tab"
            aria-controls="terms-content-panel"
            onClick={() => switchTab('terms')}
            className={`tablet:whitespace-nowrap ${
              activeSection !== 'terms' ? 'opacity-95 font-light' : 'underline'
            }`}
          >
            {t('pp-yd-review-terms-link')}
          </button>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="mx-4 border border-1 border-t-0 border-l-0 border-b-0 border-core-1 rotate-12" />
        </div>
        <div className="text-right">
          <button
            role="tab"
            aria-selected={activeSection === 'explanation'}
            id="explanation-tab"
            aria-controls="explanation-content-panel"
            onClick={() => switchTab('explanation')}
            className={`tablet:whitespace-nowrap ${
              activeSection !== 'explanation'
                ? 'opacity-95 font-light'
                : 'underline'
            }`}
          >
            {t('pp-yd-explanation-link')}
          </button>
        </div>
      </div>
      <div
        id="terms-content-panel"
        role="tabpanel"
        aria-labelledby="terms-tab"
        className="hidden group-[.terms]/tabs:flex px-2 lmobile:px-5 laptop:px-[max(50px,_3%)] flex-1 max-w-[700px] laptop:max-w-[650px] w-full mx-auto laptop:pb-[min(50px,_3%)] desktop:pb-0 mb-[max(16px,_10%)] narrow:mb-[max(16px,_3%)] narrow:!max-w-[700px] narrow:!px-4"
      >
        <TermsSection acceptedOffer={acceptedOffer} allowEdit={allowEdit} />
      </div>
      <div
        id="explanation-content-panel"
        role="tabpanel"
        aria-labelledby="explanation-tab"
        className="hidden group-[.explanation]/tabs:flex px-2 lmobile:px-5 laptop:px-[min(50px,_10%)] flex-1 max-w-[650px] w-full mx-auto laptop:pb-[min(50px,_5%)] desktop:pb-[max(50px,_10%)]"
      >
        <ExplanationSection />
      </div>
    </aside>
  );
};

export { TermsAndExplanationTabs };
