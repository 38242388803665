import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { getNextRouteDispatcher } from '@/v2Utils/journey/Dispatchers/getNextRouteDispatcher';
import { getCurrentStepDispatcher } from '@/v2Utils/journey/Dispatchers/getCurrentStepDispatcher';
import { JourneyFlow } from '@/v2Lib/journeyFlow';
import { getJourneyPageDispatcher } from '@/v2Utils/journey/Dispatchers/getJourneyPageDispatcher';

export const useJourney = (
  originPage: string,
): {
  getNextRoute: (state?: any) => string;
  getCurrentStep: () => number;
  getJourneyPage: () => JSX.Element;
  flow: JourneyFlow;
} => {
  const { state: globalState } = useGlobalStore();
  const flow = globalState?.journeyFlow;

  const getNextRoute = (state?: any): string =>
    getNextRouteDispatcher(originPage, flow, state);
  const getCurrentStep = (): number =>
    getCurrentStepDispatcher(originPage, flow);
  const getJourneyPage = (): JSX.Element =>
    getJourneyPageDispatcher(originPage, flow);

  return { getNextRoute, getCurrentStep, getJourneyPage, flow };
};
