const UserIcon = (): React.ReactNode => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00004 5.83398C5.00004 8.59648 7.23754 10.834 10 10.834C12.7625 10.834 15 8.59648 15 5.83398C15 3.07148 12.7625 0.833984 10 0.833984C7.23754 0.833984 5.00004 3.07148 5.00004 5.83398ZM11.6667 13.619V11.8773V11.8765C9.66998 11.3609 7.54705 11.7985 5.91689 13.0615C4.28672 14.3246 3.33285 16.2709 3.33337 18.3332L5.00004 18.334C4.99973 16.7106 5.78754 15.1882 7.1129 14.2508C8.43826 13.3135 10.1362 13.0779 11.6667 13.619ZM10 9.16732C11.8417 9.16732 13.3334 7.67565 13.3334 5.83398C13.3334 3.99232 11.8417 2.50065 10 2.50065C8.15837 2.50065 6.66671 3.99232 6.66671 5.83398C6.66671 7.67565 8.15837 9.16732 10 9.16732ZM15 11.6673V14.1673H12.5V15.834H15V18.334H16.6667V15.834H19.1667V14.1673H16.6667V11.6673H15Z"
        className="fill-current"
      />
    </svg>
  );
};

export { UserIcon };
