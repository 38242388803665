import React from 'react';
import { useTranslation } from '@/app/i18n/client';

interface GaugeProps {
  currency: string;
  availableCapital: number;
}

const AvailableCapitalGauge = ({
  currency,
  availableCapital,
}: Readonly<GaugeProps>) => {
  const { t } = useTranslation();

  return (
    <div className="relative max-w-[180px] h-auto">
      <svg
        viewBox="0 0 149 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full h-auto"
      >
        <path
          d="M16.9289 112.818C14.7681 114.25 11.8418 113.665 10.5505 111.418C4.61659 101.091 1.25533 89.4609 0.785259 77.5138C0.253556 64.0003 3.44128 50.6 10.001 38.7735C16.5608 26.9469 26.2409 17.1477 37.9864 10.4439C49.7319 3.74004 63.0921 0.388742 76.6111 0.755201C90.1301 1.12166 103.289 5.19182 114.654 12.5221C126.019 19.8524 135.154 30.1616 141.064 42.3262C146.973 54.4907 149.43 68.044 148.167 81.5088C147.051 93.413 143.064 104.844 136.58 114.834C135.169 117.008 132.215 117.434 130.135 115.887C128.055 114.341 127.638 111.409 129.029 109.222C134.5 100.621 137.866 90.8254 138.822 80.6323C139.925 68.878 137.78 57.0467 132.621 46.4275C127.462 35.8084 119.488 26.8089 109.567 20.4099C99.6456 14.0109 88.1583 10.4578 76.3568 10.1379C64.5553 9.81803 52.8924 12.7436 42.6391 18.5957C32.3858 24.4479 23.9355 33.0021 18.2091 43.3262C12.4828 53.6503 9.70003 65.3481 10.1642 77.1448C10.5667 87.3747 13.3975 97.3386 18.3941 106.223C19.6646 108.482 19.0897 111.387 16.9289 112.818Z"
          fill="#4164EA"
        />
      </svg>
      <div className="absolute inset-0 flex flex-col items-center justify-center space-y-0.5">
        <span className="text-sm font-bold text-gray-800">
          {availableCapital?.toLocaleString(undefined, {
            currency: currency,
            style: 'currency',
            currencyDisplay: 'narrowSymbol',
            maximumFractionDigits: 0,
          })}
        </span>
        <span className="text-xs font-normal text-[#404A70]">
          {t('pp-yfq-available')}
        </span>
      </div>
    </div>
  );
};

export default AvailableCapitalGauge;
