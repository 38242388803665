export const ShieldIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0.832031L16.8475 2.3537C17.2283 2.4387 17.5 2.7762 17.5 3.16703V11.4895C17.5 13.1612 16.6642 14.7229 15.2733 15.6495L10 19.1654L4.72667 15.6495C3.335 14.722 2.5 13.1612 2.5 11.4904V3.16703C2.5 2.7762 2.77167 2.4387 3.1525 2.3537L10 0.832031ZM10 2.53776L4.16667 3.83359V11.4878C4.16667 12.6019 4.72333 13.6428 5.65083 14.2611L10 17.1611L14.3492 14.2611C15.2767 13.6428 15.8333 12.6028 15.8333 11.4886V3.83359L10 2.53859V2.53776ZM14.8906 8.02682L13.7114 6.84849V6.84766L9.58643 10.9727L7.22977 8.61599L6.05143 9.79432L9.58727 13.3302L14.8906 8.02682Z"
        fill="currentColor"
      />
    </svg>
  );
};
