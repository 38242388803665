import { isAxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { EventTypes, useLogEvent } from '@/app/hooks/useLogEvent';
import { useLiberisAPI } from '@/app/network/useLiberisApi';
import { ApiPath } from '@/app/network/ApiPath';
import { usePartner } from '@/lib/partners/usePartner/client';
import { FlexiAccountOfferResponse } from '@/app/api/[partner]/flexi/types';
import {
  Applicant,
  Application,
  Company,
  Consents,
} from '@/lib/types/OffersPayload.types';
import { BankDetails } from '@/lib/types/BankDetails.type';

export type FlexiCreateApplicationProps = {
  application: Application;
  applicants: Applicant[];
  bankDetails: BankDetails;
  company: Company;
  consents: Consents;
  offer: FlexiAccountOfferResponse;
};

/**
 * @description Custom hook to fetch flexi offer data by merchant id. Will skip fetching if merchant id is null or undefined.
 *
 * @param externalId The partner client id of the merchant
 * @returns {createApplication, isPending, isError, error, isSuccess} The merchant data, loading state, and error state
 */
const useFlexiCreateApplication = (props: FlexiCreateApplicationProps) => {
  let userTraceId: string;
  let userSpanId: string;
  const logError = useLogEvent(EventTypes.ERROR);
  const api = useLiberisAPI();
  const partner = usePartner();

  const {
    mutate: createApplication,
    isPending,
    isError,
    error,
    isSuccess,
  } = useMutation({
    mutationFn: async () => {
      try {
        await api.post<FlexiAccountOfferResponse>(
          `/api/${partner}/${ApiPath.FLEXI}/application`,
          {
            ...props,
          },
        );

        return 200;
      } catch (error) {
        logError(error instanceof Error ? error : new Error('Unknown error'));
        if (isAxiosError(error)) {
          const { response, request } = error;
          if (response) {
            return response.status;
          } else if (request) {
            logError(new Error(request.statusText), userTraceId, userSpanId);
            return null;
          } else {
            if (request.statusText === 'abort') {
              // Aborting a request is not an error
              return null;
            }
            logError(new Error(error.message), userTraceId, userSpanId);
            return null;
          }
        } else if (error instanceof Error) {
          logError(error, userTraceId, userSpanId);
          return null;
        } else {
          logError(new Error('Unknown error'), userTraceId, userSpanId);
          return null;
        }
      }
    },
  });

  return {
    createApplication,
    isPending,
    isError,
    error,
    isSuccess,
  };
};

export { useFlexiCreateApplication };
