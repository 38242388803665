import { UserDetailsIcon } from '@/app/[lang]/[partner]/components/UserDetailsIcon';
import { SectionValues } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/SectionValues';
import IndividualDetails from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/PersonalDetails/IndividualDetails';
import { ReviewSection } from '@/app/[lang]/[partner]/(PrepopulatedJourney)/v2/review/components/ReviewSection';
import {
  businessTypeValues,
  getBusinessTypeFamily,
} from '@/lib/businessTypeRoutes';

export type FirstShareholderDetailsReviewSectionProps = {
  isComplete: boolean;
  editUrl: string;
  pageState: any;
};

export const FirstShareholderDetailsReviewSection = (
  props: FirstShareholderDetailsReviewSectionProps,
) => {
  const showPercentage_ownership =
    props.pageState?.offersPayload?.company?.business_type &&
    getBusinessTypeFamily(
      props.pageState?.offersPayload?.company?.business_type,
    ) !== businessTypeValues.sole_trader &&
    getBusinessTypeFamily(
      props.pageState?.offersPayload?.company?.business_type,
    ) !== businessTypeValues.partnership;

  return (
    <ReviewSection
      isComplete={props.isComplete}
      section={
        props.pageState?.offersPayload?.company?.business_type ===
        businessTypeValues.partnership
          ? 'partnership-details'
          : 'shareholder-details'
      }
      icon={
        <span className="inline-block p-1 w-full h-full">
          <UserDetailsIcon />
        </span>
      }
      shareholderIndex={1}
      shareHolderPercentage={
        showPercentage_ownership &&
        props.pageState?.offersPayload?.applicants?.[1]?.ownership_percentage
      }
    >
      <SectionValues
        editURL={props.editUrl}
        isComplete={props.isComplete}
        shareholderIndex={1}
      >
        <dl>
          <IndividualDetails
            details={props.pageState?.offersPayload?.applicants?.[1]}
          />
        </dl>
      </SectionValues>
    </ReviewSection>
  );
};
