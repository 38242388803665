'use client';

import { generateUniqueId } from '@/lib/utils/generateUniqueId';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'next/navigation';
import { ApiCalls, EventTypes, useLogEvent } from '@/app/hooks/useLogEvent';

export const fetchInstitution = async ({
  payload,
  partner,
  traceId,
  spanId,
  logError,
  logApiCall,
}: {
  payload: any;
  partner: string;
  traceId: string;
  spanId: string;

  logError: (
    error: Error,
    traceId?: string | undefined,
    spanId?: string | undefined,
  ) => void;
  logApiCall: (requestName: string, traceId: string, spanId: string) => void;
}) => {
  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');
  const partnerURLHost = host_url?.replace('{partner}', partner);

  try {
    logApiCall(ApiCalls.INSTITUTION_CHECK, traceId, spanId);
    const response = await fetch(
      `${partnerURLHost}/api/${partner}/institution?routingNumber=${payload}`,
      {
        method: 'GET',
        headers: {
          'X-Datadog-Trace-Id': traceId,
          'X-Datadog-Parent-Id': spanId,
          'X-Datadog-Origin': 'local',
          'X-Datadog-Sampling-Priority': '1',
        },
        cache: 'no-store',
      },
    );

    if (response.ok) {
      const jsonResponse = await response.json();

      return jsonResponse;
    } else {
      logError(new Error(`Insitution endpoint not ok`), traceId, spanId);
      const errorResponse = { error: 'Institution endpoint not ok' };
      return errorResponse;
    }
  } catch (error: any) {
    logError(error, traceId, spanId);
    return { error: error.message };
  }
};

export const useInstitution = (onSuccess: (value: string) => void) => {
  const params: { partner: string } = useParams();

  const logApiCall = useLogEvent(EventTypes.API_CALL);
  const logError = useLogEvent(EventTypes.ERROR);

  const traceId = typeof window !== 'undefined' ? generateUniqueId() : '';
  const spanId = typeof window !== 'undefined' ? generateUniqueId() : '';

  const InstParams = {
    partner: params.partner,
    traceId,
    spanId,
    logError,
    logApiCall,
  };

  return useMutation({
    mutationFn: (routingNumber: string) => {
      const params = { payload: routingNumber, ...InstParams };
      return fetchInstitution(params);
    },
    onSuccess: onSuccess,
  });
};
